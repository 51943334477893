import http from "../http-common";

export default {
    get(body) {
        return http.post('/operation', body);
    },
    codeValidate(body) {
        return http.post('/operation/validate', body);
    },
}


<template>
  <div>
  <Breadcrumbs main="АБОН. ПЛАТА" title="ПОДПИСКА" />
  <div class="container invoice" id="print">
    <div class="row">
      <div class="col-sm-12">
        <div class="card">
          <div class="card-body">
            <div>
              <div>
                <div class="row invo-header">
                  <div class="col-sm-10">
                    <div class="d-flex align-items-center">
                      <div class="flex-grow-1 m-l-20">
                        <h4 class="d-flex-headin">ДОСТУП К ПЛАТФОРМЕ “РАНТЬЕ”</h4>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <hr />
              <div class="row invo-profile">

                <div class="col">
                  <div class="invo-profile-right">
                    <div id="project" class="text-xl-start">
                      <p>Ваша подписка на использование программного комплекса</p>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <alert-error v-if="errorMessage" :text="errorMessage" />
                <alert-success v-if="successMessage" :text="successMessage" />

                <div class="table-responsive invoice-table" id="table">
                  <InvoiceTable v-if="startDate" :startDate="startDate" :endDate="endDate" />
                </div>
                <div class="row mt-3">
                  <div class="col-md-8" v-if="startDate">
                    <div>
                      <p class="legal"><strong>Благодарим за использование системы.</strong></p>
                    </div>
                  </div>
                  <div class="row mt-3" v-if="!startDate">
                    <div class="col">
                      <div>
                        <p class="legal">На данный момент у Вас нет активной подписки на использование программного
                          комплекса. Стоимость использования на данный момент составляет: 1 500 рублей на год.
                        </p>
                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </div>
            <div class="mt-3" v-if="!startDate && idSystemPayment < 3">
              <div class="form-inline">
                <div class="m-2">
                  <button class="btn btn btn-primary" type="button" v-if="!isCode" @click="clickPayment">
                    Оплатить подписку
                  </button>
                </div>
                <div class="mt-2 form-check">
                  <input type="checkbox" class="form-check-input" id="isAmountAccount" @change="checkBox"
                    v-model="isAmountAccount" :disabled="isCode">
                  <label class="form-check-label" for="isAmountAccount">Зачесть в погашение 100% стоимости средства со
                    внутреннего счета. <a href="../documents/internal-credit" target="_blank"
                      v-if="isAmountAccount">Заявление
                      на зачет.</a></label>
                </div>
              </div>
              <codeConfirmation :isCode=isCode :validate=validate :isAmountAccount=isAmountAccount :idSystemPayment=idSystemPayment :paymentData=paymentData :typePayment=1 />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
</template>
<script>
import InvoiceTable from './activateTable.vue';
import SubscriptionDataService from "@/services/SubscriptionDataService";
import MoneyDataService from "@/services/MoneyDataService";
import OperationsDataService from "@/services/OperationsDataService";
import AlertSuccess from "@/components/alert-succes.vue";
import AlertError from "@/components/alert-error.vue";
import codeConfirmation from "@/components/codeConfirmation.vue"

export default {
  
  name: 'invoiceView',
  components: {
    AlertError,
    AlertSuccess,
    InvoiceTable,
    codeConfirmation
  },
  data() {
    return {
      isAmountAccount: false,
      errorMessage: '',
      successMessage: '',
      startDate: Date,
      endDate: Date,
      idSubscription: Number,
      idSystemPayment: 0,
      isCode: false,
      validate: {
        id: Number,
        code: '',
      },
      paymentData: {
        name: 'Абонентская плата',
        summa: 1500,
        typePayment: 1,
        isAmountAccount: false
      },
    }
  },
  mounted() {
    MoneyDataService.getIdPaymentService()
      .then(response => {
        this.idSystemPayment = Number(response.data.idSystemPayment);
      })
      .catch(e => {
        console.log(e);
      });

    SubscriptionDataService.get()
      .then(response => {
        this.startDate = response.data.startDate;
        this.endDate = response.data.endDate;
        this.idSubscription = (response.data.id) ? response.data.id : 'Не активна';
      })
      .catch(e => {
        console.log(e);
      });
  },
  methods: {
    clickPayment() {
      //код показывать
      this.isCode = true;

      OperationsDataService
        .get({ typeOperation: 1 })
        .then(response => {
          this.validate.id = response.data;
        })
    },
    codeValidate() {
      OperationsDataService
        .codeValidate(this.validate)
        .then(response => {
          const isValidate = response.data;
          if (isValidate) this.choiceAcquiring();
          else this.errorMessage = 'Не верный код. Попробуйте ввести заново, или обновите страницу и сгенерируйте новый.'
        })
    },
    choiceAcquiring() {
      localStorage.setItem('paymentData', JSON.stringify(this.paymentData));
      if (this.isAmountAccount) this.makePaymentRedirect();
      if (this.idSystemPayment === 0 && !this.isAmountAccount) window.location.href = '/my/payments/cloudPayments';
      if (this.idSystemPayment === 1 && !this.isAmountAccount) this.makePaymentRedirect();
    },
    makePaymentRedirect() {
      MoneyDataService.payment({
        typePayment: 1,
        selectPaymentSystem: 1,
        isAmountAccount: this.isAmountAccount,
        summa: 0,
      })
        .then(response => {

          if (this.isAmountAccount)
            this.successMessage = 'Благодарим за покупку.'
          else {
            window.location.href = response.data.url;
            this.successMessage = 'Оплатите подписку на сайте банка.';
          }
        })
        .catch(e => {
          this.errorMessage = 'Ошибка приобретения подписки. ' + e.response.data.message;
          console.log(e);
        });
    },
    checkBox() {
      if (this.isAmountAccount) {
        localStorage.setItem('isAmountAccount', true);
      } else {
        localStorage.removeItem('isAmountAccount');
      }
    }
  },
}
</script>
<template>
  <div>
    <Breadcrumbs title="МОЯ СЕТЬ"/>
    <div class="container-fluid wrapper-network">
        <div class="container">
          <div class="modal" v-if="isOpenModal"  >
            <div class="modal-overlay" @click="closeModal"></div>
            <div class="modal-content" :class="{ 'slide-enter-top': isOpenModal}">
              <div class="close">
                <img src="../../assets/images/network/Close.svg" alt="Close" @click="closeModal">
              </div>
              <modal @close="closeModal"  :fio="this.networkUser.profile.fio" :email="this.networkUser.email" :phone="this.networkUser.profile.phone" :rank="this.networkUser.rank.name" :id="networkUser.id" />
<!--              :backgroundImage="this.networkUser.profile.backgroundImage"-->
            </div>
          </div>
          <div class="row">
            <div ref="card1" :class="{ 'slide-enter-left': animationPlayed.card1, 'isVisible': !animationPlayed.card1 }" class="item-card-lending col-xxl-6 col-xl-6 col-md-12">
              <div class="card-item d-flex justify-content-between">
                <div class="left">
                  <div class="title">
                    Личный кабинет, регистрация
                  </div>
                  <div class="desription">
                    Ваша персональная ссылка для регистрации нового пользователя
                    <div>
                      <a href="javascript:{}" @click.stop="copyLink">
                        <code>
                          https://rentier.world/my/auth/register?start={{ referralCode }}</code><br/>
                        <br/>
                        Копировать <i class="icofont icofont-copy-alt"></i><br/>
                      </a>
                    </div>
                  </div>
                </div>
                <div class="right">
                  <div class="prev">
                    Перейти
                  </div>
                </div>
              </div>
            </div>
            <div ref="card2" :class="{ 'slide-enter-right': animationPlayed.card2, 'isVisible': !animationPlayed.card2 }" class="item-card-lending col-xxl-6 col-xl-6 col-md-12">
              <div class="card-item d-flex justify-content-between">
                <div class="left">
                  <div class="title">
                    Лендинг
                  </div>
                  <div class="desription">
                    Ваша персональная ссылка для регистрации нового пользователя
                    <div>
                      <a href="javascript:{}" @click.stop="copyLink2">
                        <code>
                          https://rentier.city/?start={{ referralCode }}</code><br/>
                        <br/>
                        Копировать <i class="icofont icofont-copy-alt"></i>
                      </a>
                    </div>
                  </div>
                </div>
                <div class="right">
                  <div class="prev">
                    Перейти
                  </div>
                </div>
              </div>
            </div>
           </div>

            <div ref="card4" :class="{ 'slide-enter-top': animationPlayed.card4, 'isVisible': !animationPlayed.card4 }">
              <IncomeChart />
            </div>


          <div ref="card3" :class="{ 'slide-enter-top': animationPlayed.card3, 'isVisible': !animationPlayed.card3 }" class="col-12 mt-4 mb-5">
              <div class="card-item-network">
                <div class="left">
                  <div class="title d-flex justify-content-between">
                    <div class="title-left">
                      Моя сеть
                    </div>
                    <div class="title-right">
<!--                      <img src="../../assets/images/network/voprosik.svg" alt="">-->
                    </div>
                  </div>
                </div>
                <div class="accordion-wrapper pt-3">
                  <div class="title-accordion d-flex">`
                    <div class="title-accordion-item item-accordion-one">Имя</div>
                    <div class="title-accordion-item mobile">Приватность</div>
                    <div class="title-accordion-item">Кол-во партнеров</div>
                  </div>
                  <div v-for="(item, index) in network.children" :key="item.id">
                    <div class="wrapper-item-accordion d-flex align-items-center mt-4 pb-2">
                    <div class="item-accordion item-accordion-one d-flex" @click="OpenModal(item.id, item.profileSettings[0].isEnable)">
                      <div class="item-accordion-images d-flex">
                        <div  class="avatar-circle">
<!--                          v-if="item.profile.avatar === null"-->
                          {{ getFirstLetter(item.profile.fio) }}
                        </div>
<!--                        <img :src="this.FRONT_URL + item.profile.avatar" v-else alt="">-->
                        <img src="../../assets/images/network/LvlNetwork.svg" class="item-accordion-images-two mobile" alt="">
                      </div>
                      <div class="item-accordion-description">
                        <div :class="item.isActivate ? 'fio activateUser' : 'fio'">
                          {{item.profile.fio}}
                        </div>
                        <div class="email" v-if="item.profileSettings[0].isEnable === false">
                          {{item.email}}
                        </div>
                        <div class="email" v-else-if="item.profileSettings[0].isEnable === true">
                          Почта скрыта
                        </div>
                      </div>
                    </div>
                    <div class="item-accordion mobile" @click="OpenChildren(item.id)" >
                      <img src="../../assets/images/network/unlock.svg" alt="" v-if="item.profileSettings[0].isEnable === false">
                      <img src="../../assets/images/network/lock.svg" alt="" v-else-if="item.profileSettings[0].isEnable === true">
                    </div>
                    <div class="item-accordion item-accordion-count" @click="OpenChildren(item.id)">
                      {{item.countChildren}}
                    </div>
                      <div class="item-accordion-arrow d-flex" @click="OpenChildren(item.id)">
                        <vue-feather class="arrow-down" type="chevron-down" :class="{ rotated: item.id === rotateIndex }"></vue-feather>
                      </div>
                    </div>
                    <recursive
                        v-if="isDropdownOpen && currentIndex === item.id"
                        :index="currentIndex"
                        :children="currentChildren"
                        :openModal="OpenModal"
                    ></recursive>
                 </div>
                </div>
              </div>
            </div>
          </div>
      </div>
  </div>
</template>

<script>
import AlertError from '@/components/alert-error.vue';
import AlertSuccess from "@/components/alert-succes.vue";
import UserDataService from "@/services/UserDataService";
import networksDataService from "@/services/NetworksDataService";
import recursive from "@/pages/networks/recursiveChildren";
import modal from "@/pages/networks/modal.vue";
import IncomeChart from "@/pages/networks/IncomeChart.vue";


export default {
  data() {
    return {
      isVisible: {
        card1: false,
        card2: false,
        card3: false,
        card4: false,
      },
      animationPlayed: {
        card1: false,
        card2: false,
        card3: false,
        card4: false,
      },
      isOpenModal: false,
      isDropdownOpen: false,
      isCollapsed: {},
      currentIndex: null,
      currentChildren: [],
      isOpenChildren: false,
      referralCode: '',
      errorMessage: '',
      successMessage: '',
      rotateIndex: null,
      network: [],
      networkUser: [],
      openDropdowns: {},
    };
  },
  components: {
    IncomeChart,
    modal,
    recursive,
    AlertSuccess,
    AlertError
  },
  mounted() {
    this.initIntersectionObserver();
    UserDataService.get()
        .then(response => {
          const user = response.data;
          this.referralCode = user.user.referralCode;
        })
        .catch(e => {
          console.log(e);
        });

    networksDataService.get()
        .then(response => {
          this.network = response.data;
        })
        .catch(e => {
          console.log(e);
        });
  },

  methods: {
    OpenChildren(id) {
      if (this.currentIndex === id) {
        this.currentIndex = null;
        this.isDropdownOpen = false;
        this.rotateIndex = null;
      } else {
        this.currentIndex = id;
        this.rotateIndex = id;
        this.currentChildren = this.network.children.find(item => item.id === id).children;
        this.isDropdownOpen = true;
      }
    },
    OpenModal(id, enabled) {
      if (enabled === false) {
        networksDataService.getById(id).then(response => {
          this.networkUser = response.data;
          if (this.networkUser){
            this.isOpenModal = true
          }
        })
      }
    },
    getFirstLetter(fullName) {
      return fullName ? fullName.charAt(0).toUpperCase() : '';
    },
    closeModal() {
      this.isOpenModal = false;
    },
    initIntersectionObserver() {
      const options = {
        root: null,
        rootMargin: '0px',
        threshold: 0.5,
      };

      this.observer = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            if (entry.target === this.$refs.card1) {
              this.isVisible.card1 = true;
              this.animationPlayed.card1 = true;
            } else if (entry.target === this.$refs.card2) {
              this.isVisible.card2 = true;
              this.animationPlayed.card2 = true;
            } else if (entry.target === this.$refs.card3) {
              this.isVisible.card3 = true;
              this.animationPlayed.card3 = true;
            }else if (entry.target === this.$refs.card4) {
              this.isVisible.card4 = true;
              this.animationPlayed.card4 = true;
            }
          } else {
            if (entry.target === this.$refs.card1) {
              this.isVisible.card1 = false;
            } else if (entry.target === this.$refs.card2) {
              this.isVisible.card2 = false;
            } else if (entry.target === this.$refs.card3) {
              this.isVisible.card3 = false;
            } else if (entry.target === this.$refs.card4) {
              this.isVisible.card4 = false;
            }
          }
        });
      }, options);

      this.observer.observe(this.$refs.card1);
      this.observer.observe(this.$refs.card2);
      this.observer.observe(this.$refs.card3);
      this.observer.observe(this.$refs.card4);
    },

    copyLink() {
      navigator.clipboard.writeText(`https://rentier.world/my/auth/register?start=${this.referralCode}`);
      alert('Ссылка скопирована')
    },
    copyLink2() {
      navigator.clipboard.writeText(`https://rentier.city?start=${this.referralCode}`);
      alert('Ссылка скопирована')
    }
  }
};


</script>

<style scoped>
.close{
  position: absolute;
  right: 20px;
  top: 10px;
  cursor: pointer;
}
.avatar-circle {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #7366FF;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
}
.arrow-down {
  transition: transform 0.3s ease;
}
.wrapper-item-accordion{
  cursor: pointer;
  border-bottom: 1px solid rgba(222, 226, 230, 1)
}
.rotated {
  transform: rotate(90deg);
}
.email{
  color: rgba(82, 82, 108, 0.75);
  font-size: 12px;
  line-height: 20px;
  font-weight: 400;
}

.item-accordion-count{
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
}

.fio{
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
}

.activateUser{
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  text-align: left;

  color: #54BA4A;
}

.item-accordion-description{
  margin-left: 8px;
}

.item-accordion-images-two{
  margin-left: 8px;
}

.wrapper-network{
  max-width: 1000px;
  margin: 0 auto;
}

.item-accordion{
  width: 21%;
}

.title-accordion-item{
  color: rgba(82, 82, 108, 0.75);
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  width: 25%;
}

.item-accordion-one{
  width: 60%;
}

code{
  padding: 0 !important;
  margin: 0 !important;
}

.left{
  width: 100%;
}

.card-item {
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 1);
  padding: 30px 33px 25px 33px;
  box-shadow: 0px 9px 20px 0px rgba(46, 35, 94, 0.07);
  border-radius: 15px;
}

.card-item-network {
  flex: 0 0 auto;
  background-color: rgba(255, 255, 255, 1);
  padding: 30px 33px 25px 33px;
  box-shadow: 0px 9px 20px 0px rgba(46, 35, 94, 0.07);
  border-radius: 15px;
}

.prev{
  color: rgba(82, 82, 108, 0.75);
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
}
.title{
  color: rgba(47, 47, 59, 1);
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
}

.desription{
  margin-top:8px;
  margin-bottom:12px;
  color: rgba(82, 82, 108, 0.75);
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
}

.isVisible{
  opacity: 0;
}

.slide-enter-left {
  animation: slide-enter-left 0.8s ease-out;
}

.slide-enter-top {
  animation: slide-enter-top 0.8s ease-out;
}

.slide-enter-right {
  animation: slide-enter-right 0.8s ease-out;
}
.modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.7);
}

.modal-content {
  position: relative;
  background: white;
  border-radius: 15px;
  z-index: 1001;
  max-width: 498px;
  width: 100%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}
@media screen and (max-width: 1200px) {
  .mobile{
    display: none;
  }
  .item-card-lending{
    margin-top: 25px;
  }
  .prev{
    display:none;
  }
}
@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

@keyframes slide-enter-left {
  0% {
    opacity: 0;
    transform: translateX(-50px);
  }

  100% {
    opacity: 1;
    transform: translateX(0);
  }
}
@keyframes slide-enter-top {
  0% {
    opacity: 0;
    transform: translateY(50px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
@keyframes slide-enter-right {
  0% {
    opacity: 0;
    transform: translateX(50px);
  }

  100% {
    opacity: 1;
    transform: translateX(0);
  }
}
</style>
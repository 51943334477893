<template>
  <div class="w-100 h-100 p-0">
    <div class="row flex-column flex-sm-row h-100">
      <div class="col-lg-6 col-sm-6 pe-md-2  pb-2 wrapper-item">
        <div class="card-item h-100 p-2 w-100 d-flex justify-content-between align-items-center ">
          <div class="card-item-left d-flex flex-column justify-content-center h-100 ms-3">
            <div class="circle1"></div>
            <li class="onhover-dropdown pe-0 py-0">
              <span class="f-light">ЛИЧНЫЕ ПАРТНЁРЫ</span>
            </li>
            <div class="d-flex align-items-end gap-1">
              <h4>{{ active6 ? '**' : format(data[0]) }}</h4>
              <!--              <h4>{{ !active6 ? '**' : parseInt(format(data[0])) }}</h4>-->
              <span class="font-primary f-12 f-w-500">

                <span v-if="!active6">+{{ data[1] }}</span>
              </span>
            </div>
          </div>
          <div class="card-item-right d-flex flex-column justify-content-between align-items-center h-100 me-2">
            <div class="bg-gradient z-1">
              <!--              <svg class="stroke-icon svg-fill">-->
              <!--                <use xlink:href="/img/icon-sprite.cec624bc.svg#new-order"></use>-->
              <!--              </svg>-->
              <div class="show-hides z-3" @click.prevent="show('active6')">
                <img v-if="active6" class="svg-icon icon-eye" src="@/assets/svg/eye-close.svg" alt="">
                <img v-else class="svg-icon icon-eye" src="@/assets/svg/eye-open.svg" alt="">
              </div>
            </div>
            <div>
              <li class="onhover-dropdowns pe-0 py-0">
                <vue-feather @mouseover="showPopover1=true" @mouseleave="showPopover1=false"
                             class="status_toggle middle sidebar-toggle" type="help-circle"></vue-feather>
                <ul class="onhover-show-divs p-3">
                  Количество партнеров на первом уровне и рост за месяц.
                </ul>
              </li>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-6 col-sm-6 ps-md-2  pb-2 ">
        <div class="card-item h-100 p-2 w-100 d-flex justify-content-between align-items-center">
          <div class="card-item-left d-flex flex-column justify-content-center h-100 ms-3">
            <li class="onhover-dropdown pe-0 py-0">
              <span class="f-light">МОЯ СЕТЬ</span>
            </li>
            <div class="d-flex align-items-end gap-1">
              <h4>{{ active7 ? '**' : format(data[2]) }}</h4>
              <!--              <h4>{{ !active6 ? '**' : parseInt(format(data[0])) }}</h4>-->
              <span class="font-primary f-12 f-w-500">

                <span v-if="!active7">+{{ data[3] }}</span>
              </span>
            </div>
          </div>
          <div class="card-item-right d-flex flex-column justify-content-between align-items-center h-100 me-2">
            <div class="bg-gradient z-1">
              <!--              <svg class="stroke-icon svg-fill">-->
              <!--                <use xlink:href="/img/icon-sprite.cec624bc.svg#new-order"></use>-->
              <!--              </svg>-->
              <div class="show-hides" @click.prevent="show('active7')">
                <img v-if="active7" class="svg-icon icon-eye" src="@/assets/svg/eye-close.svg" alt="">
                <img v-else class="svg-icon icon-eye" src="@/assets/svg/eye-open.svg" alt="">
              </div>
            </div>
            <div>
              <li class="onhover-dropdowns pe-0 py-0">
                <vue-feather @mouseover="showPopover1=true" @mouseleave="showPopover1=false"
                             class="status_toggle middle sidebar-toggle" type="help-circle"></vue-feather>
                <ul class="onhover-show-divs p-3">
                  Количество партнеров на всех уровнях и рост за месяц.
                </ul>
              </li>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-6 col-sm-6 pe-md-2  pt-2 ">
        <div class="card-item h-100 p-2 w-100 d-flex justify-content-between align-items-center">
          <div class="card-item-left d-flex flex-column justify-content-center h-100 ms-3">
            <div class="circle2"></div>
            <li class="onhover-dropdown pe-0 py-0">
              <span class="f-light">МОИ АКТИВЫ</span>
            </li>
            <div class="d-flex align-items-end gap-1">
              <h4>{{ active3 ? '****' : format(totalValue) + "&#8381;" }}</h4>
              <!--              <h4>{{ !active6 ? '**' : parseInt(format(data[0])) }}</h4>-->
              <span class="font-primary f-12 f-w-500">

                <span v-if="!active3">+{{ percentageChange }}%</span>
              </span>
            </div>
          </div>
          <div class="card-item-right d-flex flex-column justify-content-between align-items-center h-100 me-2">
            <div class="bg-gradient z-1">
              <!--              <svg class="stroke-icon svg-fill">-->
              <!--                <use xlink:href="/img/icon-sprite.cec624bc.svg#new-order"></use>-->
              <!--              </svg>-->
              <div class="show-hides" @click.prevent="show('active3')">
                <img v-if="active3" class="svg-icon icon-eye" src="@/assets/svg/eye-close.svg" alt="">
                <img v-else class="svg-icon icon-eye" src="@/assets/svg/eye-open.svg" alt="">
              </div>
            </div>
            <div>
              <li class="onhover-dropdowns pe-0 py-0">
                <vue-feather @mouseover="showPopover1=true" @mouseleave="showPopover1=false"
                             class="status_toggle middle sidebar-toggle" type="help-circle"></vue-feather>
                <ul class="onhover-show-divs p-3">
                  Сумма активов, и изменение от стоимости покупки.
                </ul>
              </li>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-6 col-sm-6 ps-md-2  pt-2 ">
        <div class="card-item h-100 p-2 w-100 d-flex justify-content-between align-items-center">
          <div class="card-item-left d-flex flex-column justify-content-center h-100 ms-3">
            <li class="onhover-dropdown pe-0 py-0">
              <span class="f-light">ДОХОД ЗА МЕСЯЦ</span>
            </li>
            <div class="d-flex align-items-end gap-1">
              <h4>{{ active1 ? '****' : format(profit.profitInMonth) + "&#8381;" }}</h4>
              <!--              <h4>{{ !active6 ? '**' : parseInt(format(data[0])) }}</h4>-->
            </div>
          </div>
          <div class="card-item-right d-flex flex-column justify-content-between align-items-center h-100 me-2">
            <div class="bg-gradient z-1">
              <!--              <svg class="stroke-icon svg-fill">-->
              <!--                <use xlink:href="/img/icon-sprite.cec624bc.svg#new-order"></use>-->
              <!--              </svg>-->
              <div class="show-hides" @click.prevent="show('active1')">
                <img v-if="active1" class="svg-icon icon-eye" src="@/assets/svg/eye-close.svg" alt="">
                <img v-else class="svg-icon icon-eye" src="@/assets/svg/eye-open.svg" alt="">
              </div>
            </div>
            <div>
              <li class="onhover-dropdowns pe-0 py-0">
                <vue-feather @mouseover="showPopover1=true" @mouseleave="showPopover1=false"
                             class="status_toggle middle sidebar-toggle" type="help-circle"></vue-feather>
                <ul class="onhover-show-divs p-3">
                  Ваш доход за текущий месяц.
                </ul>
              </li>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>
<script>
import DashboardDataService from "@/services/DashboardDataService";
import MoneyDataService from "@/services/MoneyDataService";
import alertError from "@/components/alert-error.vue";
import AlertSuccess from "@/components/alert-succes.vue";
import AlertWarning from "@/components/alert-warning.vue";
import AlertInfo from "@/components/alert-info.vue";
import SubscriptionDataService from "@/services/SubscriptionDataService";
import CertificateDataService from "@/services/CertificateDataService";

export default {
  components: {
    AlertInfo,
    AlertWarning,
    AlertSuccess,
    alertError
  },
  data() {
    return {
      active1: true,
      active2: true,
      active3: true,
      active4: true,
      active5: true,
      active6: true,
      active7: true,
      showPopover1: false,
      showPopover2: false,
      showPopover3: false,
      showPopover4: false,
      showPopover5: false,
      showPopover6: false,
      showPopover7: false,
      data: {},
      totalValue: Number,
      percentageChange: Number,
      credit: {},
      isSubscription: true,
      isPaymentMoth: true,
      accountActivityEndDate: null,
      profit: 0,
      validate: {
        id: Number,
        code: "",
      },
    };
  },
  methods: {
    show(prop) {
      this[prop] = !this[prop];
    },
    format: (val) =>
        Number(val).toLocaleString(),
  },

  mounted() {
    CertificateDataService.accountActivityEndDate().then((response) => {
      this.accountActivityEndDate = response.data;
    });

    CertificateDataService.isPaymentMoth().then((response) => {
      this.isPaymentMoth = response.data;
    });

    SubscriptionDataService.activate().then((response) => {
      this.isSubscription = response.data;
    });

    MoneyDataService.getCreditTinkoff().then((response) => {
      this.credit = response.data;
    });

    MoneyDataService.getProfit().then((response) => {
      this.profit = response.data;
    });

    MoneyDataService.get().then((response) => {
      this.AvailableWithdrawal = response.data[0];

    });
    DashboardDataService.get()
        .then((response) => {
          this.data = response.data;
          this.totalValue = this.data[4].totalValue;
          this.percentageChange = this.data[4].percentageChange;
        })
        .catch((e) => {
          console.log(e);
        });
  },
};
</script>

<style scoped>

.card-item {
  border: none;
  transition: all 0.3s ease;
  letter-spacing: 0.5px;
  border-radius: 15px;
  box-shadow: 0px 9px 20px rgba(46, 35, 94, 0.07);
  position: relative;
  background: #ffff;
}
li{
  list-style: none;
}
.icon-eye {
  width: 30px !important;
  height: 30px !important;
}

.show-hide {
  top: 10px !important;
  right: 15px !important;
}

.sidebar-toggle {
  color: #52526C;
}

.circle1{
  background: linear-gradient(180deg, #EDF8EC 0%, #FFFFFF 100%);
  position: absolute;
  right: 10px;
  bottom: 10px;
  z-index: 0;
  width: 60px;
  height: 60px;
  border-radius: 50%;
}
.circle2{
  background: linear-gradient(180deg, #FFF6E5 0%, #FFFFFF 100%);
  position: absolute;
  right: 10px;
  bottom: 10px;
  z-index: 0;
  width: 60px;
  height: 60px;
  border-radius: 50%;
}
</style>
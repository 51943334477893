<template>
  <div class="col-xl-4">
    <div class="card">
      <div class="card-header">
        <h4 class="card-title mb-0">МОЙ ПРОФИЛЬ</h4>
        <div class="card-options">
          <a
            class="card-options-collapse"
            href="javascript:void(0)"
            data-bs-toggle="card-collapse"
            ><i class="fe fe-chevron-up"></i></a
          ><a
            class="card-options-remove"
            href="javascript:void(0)"
            data-bs-toggle="card-remove"
            ><i class="fe fe-x"></i
          ></a>
        </div>
      </div>
      <div class="card-body">
        <p class="alert alert-warning" v-if="errorMessage">
          {{ errorMessage }}
        </p>
        <p class="alert alert-success" v-if="successMessage">
          {{ successMessage }}
        </p>
        <form @submit.prevent="updatePassword">
          <div class="row mb-2">
            <div class="profile-title">
              <div class="media">
                <img
                  class="img-70 rounded-circle"
                  alt=""
                  src="@/assets/images/user/7.jpg"
                />
                <div class="media-body">
                  <router-link to="/users/profile">
                    <h5 class="mb-1">{{ fio }}</h5>
                  </router-link>
                  <p>{{ rank }}</p>
                </div>
              </div>
            </div>
          </div>
          <div class="mb-3">
            <h6 class="form-label">О себе</h6>
            <textarea class="form-control" rows="5" disabled></textarea>
          </div>
          <div class="mb-3">
            <label class="form-label">Email</label>
            <input
              class="form-control"
              placeholder="your-email@domain.com"
              disabled
              v-model="email"
            />
          </div>
          <div class="mb-3">
            <label class="form-label">Пароль</label>
            <input
              class="form-control"
              type="password"
              v-model="password"
              placeholder="Введите новый пароль"
            />
          </div>
          <div class="form-footer">
            <button class="btn btn-primary btn-block" type="submit">
              Сохранить
            </button>
          </div>
        </form>
      </div>
    </div>
    <div class="card">
      <div class="card-header">
        <div class="title">СТАТУС ВЕРИФИКАЦИИ:</div>
      </div>
      <div class="card-body">
        <div class="row">
          <div
            v-if="this.statusVerification === null"
            class="col-sm-12 col-md-12 col-xl-12 d-flex justify-content-around aling-items-center mt-3"
          >
            <div class="row">
              <div class="col-sm-12 col-md-12 col-xl-12 discription_status alert alert-light dark">
                Верификация не пройдена
              </div>
              <div
                class="col-sm-12 col-md-12 col-xl-12 d-flex justify-content-center aling-items-center mt-3"
              >
                <verifications />
              </div>
            </div>
          </div>
          <div
            v-if="this.statusVerification === 1"
            class="col-sm-12 col-md-12 col-xl-12 d-flex justify-content-around aling-items-center mt-3"
          >
            <div class="discription_status alert alert-light dark">
              Верификация на проверке
            </div>
          </div>
          <div
            v-if="statusVerification === 2"
            class="d-flex flex-column justify-content-around aling-items-center mt-3"
          >
            <div class="text-center">Верификация отклонена!</div>
            <div class="row mt-3">
              <div class="mb-2">

                  <b> Причина: </b>

                <p>
                  {{ comment }}
                </p>
              </div>

              <verifications />
              <div
                class="col-sm-12 col-md-6 col-xl-4 popover-container d-flex justify-content-center aling-items-center"
              >
              </div>
            </div>
          </div>
          <div
            v-if="statusVerification === 3"
            class="d-flex justify-content-center aling-items-center mt-3"
          >
            <div class="btn btn-success">Верификация пройдена</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import UserDataService from "@/services/UserDataService";
import VerificarionsDataService from "@/services/VerificarionsDataService";
import verifications from "./verifications.vue";

export default {
  name: "sidePage",
  components: {
    verifications,
  },
  data() {
    return {
      errorMessage: "",
      successMessage: "",
      email: "",
      password: "",
      fio: "",
      rank: "",
      showPopover: false,
      statusVerification: null,
      comment: "",
      idappeall: null,
    };
  },
  methods: {
    getUser() {
      UserDataService.get()
        .then((response) => {
          const user = response.data;
          this.email = user.user.email;
          this.statusVerification = user.passportVerification.status.id;
          this.comment = user.passportVerification.comment;
          this.idappeall = user.passportVerification.id;
          this.fio =
            user.firstName + " " + user.lastName + " " + user.middleName;
          this.rank = user.user.rank ? user.user.rank.name : "Ранг отсутствует";
        })
        .catch((e) => {
          console.log(e);
        });
    },
    updatePassword() {
      if (!this.password) {
        this.errorMessage = "Вы не ввели пароль";
        return;
      }

      UserDataService.updatePassword({ password: this.password })
        .then((response) => {
          this.successMessage = "Пароль успешно обновлен.";
        })
        .catch((e) => {
          this.errorMessage = "Ошибка обновления пароля.";
          console.log(e);
        });
    },
  },
  mounted() {
    this.getUser();
  },
};
</script>
<style scoped>
.popover-container {
  position: relative;
  display: inline-block;
}

.popover {
  position: absolute;
  top: -100%;
  left: -10%;
  transform: translateX(-100%);
  padding: 10px;
  background-color: #f1f1f1;
  border: 1px solid #ccc;
  border-radius: 4px;
  z-index: 9999;
}

.popover-icon{
  display: block;
}

.comment-danger{
  display: none;
}

@media(max-width: 768px) {
  .popover-icon {
    display: none;
  }
  .comment-danger{
    display: block;
  }
}
</style>

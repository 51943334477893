<template>
  <div class="col-xl-8">
    <form class="card" @submit.prevent="updateProfile">
      <div class="card-header">
        <h4 class="card-title mb-0">МОЙ ПРОФИЛЬ</h4>
        <div class="card-options">
          <a
            class="card-options-collapse"
            href="javascript:void(0)"
            data-bs-toggle="card-collapse"
            ><i class="fe fe-chevron-up"></i></a
          ><a
            class="card-options-remove"
            href="javascript:void(0)"
            data-bs-toggle="card-remove"
            ><i class="fe fe-x"></i
          ></a>
        </div>
      </div>
      <div class="alert-wrapper">
        <transition name="fade">
          <div v-if="successMessage" class="alert alert-success">{{ successMessage }}</div>
        </transition>
        <transition name="fade">
          <div v-if="errorMessage" class="alert alert-warning">{{ errorMessage }}</div>
        </transition>
      </div>
      <div class="card-body">
        <div class="row">
          <div class="alert">
            поля, помеченные <span class="red">*</span>, являются обязательными к заполнению
          </div>
          <div class="col-sm-6 col-md-6">
            <div class="mb-3">
              <label class="form-label">Имя <span class="red">*</span> </label>
              <input
                  :disabled="statusVerification !== null && statusVerification !== 2"
                class="form-control"
                v-model="form.firstName"
                type="text"
                placeholder="Ваше Имя"
              />
            </div>
          </div>
          <div class="col-sm-6 col-md-6">
            <div class="mb-3">
              <label class="form-label">Фамилия <span class="red">*</span></label>
              <input
                  :disabled="statusVerification !== null && statusVerification !== 2"
                class="form-control"
                v-model="form.lastName"
                type="text"
                placeholder="Ваша Фамилия"
              />
            </div>
          </div>
          <div class="col-sm-6 col-md-6">
            <div class="mb-3">
              <label class="form-label">Отчество</label>
              <input
                  :disabled="statusVerification !== null && statusVerification !== 2"
                class="form-control"
                v-model="form.middleName"
                type="text"
                placeholder="Ваше Отчество"
              />
            </div>
          </div>
          <div class="col-sm-6 col-md-6">
            <div class="mb-3">
              <label class="form-label">Telegram</label>
              <input
                class="form-control"
                v-model="form.telegram"
                type="text"
                placeholder="@nick"
              />
            </div>
          </div>
          <div class="col-sm-6 col-md-6">
            <div class="mb-3">
              <label class="form-label">Дата рождения <span class="red">*</span></label>
              <input
                  :disabled="statusVerification !== null && statusVerification !== 2"
                class="form-control"
                v-model="form.dateBirthday"
                type="date"
                placeholder="Дата рождения"
              />
            </div>
          </div>
          <div class="col-sm-6 col-md-6">
            <div class="mb-3">
              <label class="form-label">Пол <span class="red">*</span></label>
              <select
                  :disabled="statusVerification !== null && statusVerification !== 2"
                class="form-control btn-square"
                type="number"
                v-model="form.gender"
              >
                <option value="0" selected>--Выбрать--</option>
                <option value="1">--Мужской--</option>
                <option value="2">--Женский--</option>
              </select>
            </div>
          </div>


          <div class="col-sm-6 col-md-6">
            <div class="mb-3">
              <label class="form-label">Номер телефона <span class="red">*</span></label>
              <input
                class="form-control"
                v-model="form.phone"
                v-mask="'+0 000 000 0000'"
                type="text"
                placeholder="Ваш телефон"
              />
              <small>Формат +7 000 000 0000</small>
            </div>
          </div>

          <h5>Паспортные данные</h5>
          <div class="col-sm-6 col-md-6">
            <div class="mb-3">
              <label class="form-label">Серия и номер <span class="red">*</span></label>
              <input
                  :disabled="statusVerification !== null && statusVerification !== 2"
                  class="form-control"
                  v-model="form.passportNumber"
                  v-mask="'0000-000000'"
                  type="text"
                  placeholder="Серия и номер"
              />
            </div>
          </div>
          <div class="col-sm-6 col-md-6">
            <div class="mb-3">
              <label class="form-label">Дата выдачи <span class="red">*</span></label>
              <input
                :disabled="statusVerification !== null && statusVerification !== 2"
                class="form-control"
                v-model="form.passportDate"
                type="Date"
                placeholder="@Дата выдачи"
              />
            </div>
          </div>
          <div class="col-sm-6 col-md-6">
            <div class="mb-3">
              <label class="form-label">Код подразделения <span class="red">*</span></label>
              <input
                  :disabled="statusVerification !== null && statusVerification !== 2"
                class="form-control"
                v-model="form.passportCode"
                v-mask="'000-000'"
                type="text"
                placeholder="Код подразделения"
              />
            </div>
          </div>

          <div class="col-md-12">
            <div class="mb-3">
              <label class="form-label">Кем выдан <span class="red">*</span></label>
              <input
                  :disabled="statusVerification !== null && statusVerification !== 2"
                class="form-control"
                v-model="form.passportIssued"
                type="text"
                placeholder="Кем выдан?"
              />
            </div>
          </div>
          <div class="col-md-12">
            <div class="mb-3">
              <label class="form-label">Адрес регистрации <span class="red">*</span></label>
              <input
                  :disabled="statusVerification !== null && statusVerification !== 2"
                class="form-control"
                v-model="form.passportAddress"
                type="text"
                
                placeholder="Адрес регистрации"
              />
              <small
                >Просьба вводить в формате: 000000, г. Санкт-Петербург, ул.
                Строителей, д. 3 кв. 12</small
              >
            </div>
          </div>
          <div class="col-sm-6 col-md-6">
            <div class="mb-3">
              <label class="form-label">ИНН</label>
              <input
                class="form-control"
                v-model="form.inn"
                type="text"
                placeholder="ИНН"
              />
            </div>
          </div>
          <div class="col-sm-6 col-md-6">
            <div class="mb-3">
              <label class="form-label">СНИЛС</label>
              <input
                class="form-control"
                v-model="form.snils"
                type="text"
                placeholder="СНИЛС"
              />
            </div>
          </div>
        </div>
      </div>

      <div class="card-footer">
        <a href="https://rentier.world/privacy-policy.pdf">Политика конфиденциальности</a>  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <button class="btn btn-primary" style="margin-top: 10px;" type="submit">Обновить данные</button>
      </div>
    </form>
    <div
      class="modal fade"
      :class="classError"
      id="exampleModalToggle"
      aria-hidden="true"
      aria-labelledby="exampleModalToggleLabel"
      tabindex="-1"
      ref="modal"
    >
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h1 class="modal-title fs-5" id="exampleModalToggleLabel">
              ПРЕДУПРЕЖДЕНИЕ!
            </h1>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Закрыть"
            ></button>
          </div>
          <div class="modal-body">
            <p>Если вы измените данные, то вам снова нужно пройти верификацию по паспортным данным.</p>
            <p>Вы уверены, что хотите сбросить верификацию по паспортным данным?</p>
          </div>
          <div class="modal-footer">
            <div class="row">
              <div
                class="col-sm-12 col-md-6 col-xl-6 d-flex justify-content-around aling-items-center mt-3"
              >
                <div class="btn btn-danger" data-bs-dismiss="modal" aria-label="Закрыть" @click="deleteAppeal(form.passportVerification.id)">Сбросить</div>
              </div>
              <div
                class="col-sm-12 col-md-6 col-xl-6 d-flex justify-content-center aling-items-center mt-3"
              >
                <button class="btn btn-light" data-bs-dismiss="modal"
              aria-label="Закрыть">
                  Отмена
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import UserDataService from "@/services/UserDataService";
import VerificarionsDataService from "@/services/VerificarionsDataService"
import {reactive} from "vue";

export default {
  name: "editProfile",
  data() {
    return {
      errorMessage: "",
      successMessage: "",
      statusVerification: null,
      showPopover: false,
      form: {
        firstName: "",
        lastName: "",
        middleName: "",
        rank: "",
        telegram: "",
        dateBirthday: "",
        gender: 0,
        phone: "",
        passportNumber: "",
        passportDate: "",
        passportIssued: "",
        passportAddress: "",
        passportCode: "",
        inn: "",
        snils: "",
        passportVerification: {
          id: null,
          comment: "",
          status: {
            id: null,
            name: "",
            engName: "",
          },
        },
      },
    };
  },
  methods: {
    getUser() {
      UserDataService.get()
        .then((response) => {
          this.form = response.data;
          this.statusVerification =
            response.data.passportVerification.status.id;
        })
        .catch((e) => {
          console.log(e);
        });
    },
    updateProfile() {
      UserDataService.updateProfile(this.form)
        .then((response) => {
          this.successMessage = "Данные успешно обновлены.";
          setTimeout(() => this.successMessage = '', 3000); // Убираем сообщение через 3 секунды
        })
        .catch((e) => {
          this.errorMessage = "Ошибка обновления данных. " + e.response.data.message;
          setTimeout(() => this.errorMessage = '', 3000); // Убираем сообщение через 3 секунды
          console.log(e);
        });
    },
    deleteAppeal(id) {
      VerificarionsDataService.deleteAppeal(id)
      .then(({ data }) => {
          this.messageSuccess = data.message;
          location.reload();
        })
        .catch((e) => {
          console.log(e);
          this.messageError = e.message;
        });
    },
  },
  mounted() {
    this.getUser();
  },
};
</script>
<style scoped>
.popover-container {
  position: relative;
  display: inline-block;
  min-width: 30px;
}

.popover {
  position: absolute;
  top: 0%;
  left: 0px;
  transform: translate(-110%, -50%);
  padding: 10px;
  background-color: #ffffff;
  border: 1px solid #ccc;
  border-radius: 4px;
  z-index: 9999;
  min-width: 200px;
}
.red{
  color: var(--theme-secondary);
}
.alert-wrapper{
  position: fixed;
  right: 0;
  top: 150px;
  z-index: 999;
  translate: all .3;
}

.fade-enter-active, .fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to {
  opacity: 0;
}
</style>

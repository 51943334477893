<template>
    <Breadcrumbs title="ПАРТНЁРЫ"/>
    <div class="container-fluid">
      <div class="row">
        <div class="col-sm-12">
          <div class="card">
            <div class="card-header">
              <h5>ПАРНЁРЫ</h5>
            </div>
            <div class="card-body">
  
  
              
              <!--            <p>Ссылка для приглашения через бота:<code>https://t.me/rentier_world_bot?start={{ referralCode }}</code></p>-->
            </div>
  
            <alert-error v-if="errorMessage" :text="errorMessage"/>
            <alert-success v-if="successMessage" :text="successMessage"/>
  
            <table class="table table-hover">
              <thead>
              <tr>
                <th scope="col">ПАРТНЁР</th>
                <th scope="col">ССЫЛКА</th>
              </tr>
              </thead>
              <tbody>
                <tr>
                    <td>Рантье-Право | Юридические услуги</td>
                    <td>
                      <a href="https://rentier.expert/ ">
                        https://rentier.expert/ 
                      </a>
                    </td>
                </tr>
                <tr>
                    <td>Рантье-ИТ</td>
                    <td>
                      <a href="https://rentier.group/">
                        https://rentier.group/
                      </a>
                    </td>
                </tr>
                <tr>
                    <td>Рантье-страхование</td>
                    <td>
                      <a href="https://rentier.life/">
                        https://rentier.life/
                      </a>
                    </td>
                </tr>
                <tr>
                    <td>Рантье-Дизайн</td>
                    <td>
                      <a href="https://rentier.space/">
                        https://rentier.space/
                      </a>
                    </td>
                </tr>
              </tbody>
            </table>
          </div>
  
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import AlertError from '@/components/alert-error.vue';
  import AlertSuccess from "@/components/alert-succes.vue";
  import UserDataService from "@/services/UserDataService";
  import networksDataService from "@/services/NetworksDataService";
  
  export default {
    data() {
      return {
        referralCode: '',
        errorMessage: '',
        successMessage: '',
        network: [],
      };
    },
    components: {
      AlertSuccess,
      AlertError
    },
    mounted() {
      UserDataService.get()
          .then(response => {
            const user = response.data;

            this.referralCode = user.user.referralCode;
  
          })
          .catch(e => {
            console.log(e);
          });
  
      networksDataService.get()
          .then(response => {
            this.network = response.data;

          })
          .catch(e => {
            console.log(e);
          });
    },
    methods: {
      
    }
  };
  </script>
  <style scoped>
  </style>
<template>
  <div class="h-100">
    <div class="card h-100">
      <div class="card-header card-no-border">
        <h5>БАЛАНС</h5>
      </div>
      <div class="card-body pt-0">
        <div class="show-hide" @click.prevent="show('active')">
            <img v-if="active" class="svg-icon icon-eye" src="@/assets/svg/eye-close.svg" alt="">
            <img v-else class="svg-icon icon-eye" src="@/assets/svg/eye-open.svg" alt="">
          </div>
        <div class="monthly-profit position-relative d-flex ">
          <img v-if="active" class="svg-icon-graph" src="@/assets/images/graph.svg" alt="">
          <apexchart
            v-if="active"
            height="271.3"
            type="donut"
            class="svg-icon-graph-op"
            :options="ecomDashboard.options1"
            :series="ecomDashboard.series1"
          ></apexchart>

          <apexchart
            v-else
            height="271.3"
            type="donut"
            class="w-100"
            :options="ecomDashboard.options1"
            :series="ecomDashboard.series1"
          ></apexchart>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ecomDashboard } from "@/data/comon.js";
import { ecomDashboards } from "@/data/fantom.js";

export default {
  data() {
    return {
      ecomDashboard: ecomDashboard,
      ecomDashboards: ecomDashboards,
      active: true,
    };
  },
  methods: {
    show(prop) {
      this[prop] = !this[prop];
    },
  },
};
</script>
<style scoped>
.icon-eye {
  width: 30px !important;
  height: 30px !important;
}

.svg-icon-graph{
  position: absolute;
  top: 50%;
  right: 50%;
  transform: translate(50%,-50%)
}

.svg-icon-graph-op{
  opacity: 0;
}
</style>

<template>
  <div class="col-sm-12">
    <div class="card hovercard">
      <div class="user-image">
        <div class="avatar" style="margin-left: 15px;">
          <div id="profile-tour">
<!--            <img v-if="!imageAvatar && !isLoadingAvatar" alt="" src="../../../assets/images/user/7.jpg" data-intro="This is Profile image">-->
            <div
                v-if="!imageAvatar && !isLoadingAvatar"
                class="wrapper-avatar wrapper-unknown d-flex justify-content-center align-items-center text-white"
            >
                    <h4 class="avatar-text">
                      {{ firstName ? firstName.charAt(0) : '?' }}
                    </h4>
            </div>
            <img v-else-if="!isLoadingAvatar" alt="" :src="FRONT_URL + this.imageAvatar" data-intro="This is Profile image">
            <div class="wrapper-avatar" v-else>
              <div class="wrapper-loader avatar-loader h-100 w-100 d-flex justify-content-center align-items-center">
                <div class="loader-cover"></div>
              </div>
            </div>
<!--            <label for="input-file-avatar">-->
<!--              <div class="circle-edits d-flex justify-content-center align-items-center">-->
<!--                <vue-feather type="edit" class="edit"></vue-feather>-->
<!--              </div>-->
<!--            </label>-->
            <input class="input-file-avatar d-none" id='input-file-avatar' type="file" @change="handleFileAvatar">
          </div>
        </div>
      </div>
      <div class="info">
        <div class="row" data-intro="This is the your details" id="info-bar-tour">
          <div class="col-sm-6 col-lg-4 order-sm-1 order-xl-0">
            <div class="row">
              <div class="col-md-6">
                <div class="ttl-info text-start">
                  <h6><i class="fa fa-envelope"></i> Email</h6><span>{{ email }}</span>
                </div>
              </div>
              <div class="col-md-6">
                <div class="ttl-info text-start ttl-sm-mb-0">
                  <h6><i class="fa fa-calendar"></i> Регистрация</h6><span>{{ dateRegister }}</span>
                </div>
              </div>
            </div>
          </div>
          <div class="col-sm-12 col-lg-4 order-sm-0 order-xl-1">
            <div class="user-designation text-center">
              <div class="title fw-bold">{{ fio }}</div>
              <div class="desc mt-2">{{ rank }}</div>
            </div>
          </div>
          <div class="col-sm-6 col-lg-4 order-sm-2 order-xl-2">
            <div class="row">
              <div class="col-md-6">
                <div class="ttl-info text-start ttl-xs-mt">
                  <h6><i class="fa fa-phone"></i> Telegram</h6><span>{{ telegram }}</span>
                </div>
              </div>
<!--              <div class="col-md-6">-->
<!--                <div class="ttl-info text-start ttl-sm-mb-0">-->
<!--                  <h6><i class="fa fa-calendar"></i> Местоположение</h6><span>{{ dateRegister }}</span>-->
<!--                </div>-->
<!--              </div>-->

            </div>
          </div>

        </div>

      </div>

    </div>
  </div>
</template>

<script>
export default {
  props: {
    fio: {
      type: String,
      required: true,
    },
    firstName: {
      type: String,
    },
    rank: {
      type: String,
      required: true,
    },
    telegram: {
      type: String,
      required: true,
    },
    email: {
      type: String,
      required: true,
    },
    dateRegister: {
      type: String,
      required: true,
    },
    imageAvatar: {
      type: String,
    },
    isLoadingAvatar: {
      type: Boolean,
    },
    handleFileAvatar: {
      type: Function,
    }
  },
};
</script>

<template>
  <Breadcrumbs title="ИСТОРИЯ" />
  <div class="container-fluid">
    <div class="row">
      <div class="col-sm-12">
        <div class="card">
          <div class="card-header">
            <h4>ИСТОРИЯ ФИНАНСОВЫХ ОПЕРАЦИЙ</h4>
            <span
              >Здесь отображена история 100 Ваших операций</span
            >
          </div>
          <div class="card-body">
            <div class="default-according" id="accordion">
              <div class="card" v-for="(item, index) of money" :key="item.id">
                <div class="card-header" :id="'heading' + index">
                  <h5 class="mb-0">
                    <button
                      class="btn btn-link"
                      data-bs-toggle="collapse"
                      :data-bs-target="'#collapse' + index"
                      aria-expanded="true"
                      :aria-controls="'collapseOne' + index"
                      @click="toggleCollapse(index)"
                    >
                      <span class="accountType">
                        {{ item.accountType.name }}

                        <span v-if="!item.isPoint" class="font-danger">
                          {{ Number(item.points).toLocaleString() }} ₽
                        </span>
                        <span
                          v-if="!item.isAddPoint && item.isPoint"
                          class="font-warning"
                        >
                          -{{ Number(item.points).toLocaleString() }} ₽
                        </span>
                        <span
                          v-if="item.isAddPoint && item.isPoint"
                          class="font-success"
                        >
                          +{{ Number(item.points).toLocaleString() }} ₽
                        </span>

                        {{
                          new Date(item.created_at).toLocaleDateString("ru-Ru")
                        }}
                      </span>
                    </button>

                    <div class="sidebar-toggles">
                      <vue-feather
                        class="status_toggle middle sidebar-toggle"
                        type="chevron-down"
                        id="sidebar-toggle"
                        :class="{ rotate180: isCollapsed[index] }"
                      ></vue-feather>
                    </div>
                  </h5>
                </div>
                <div
                  class="collapse"
                  :id="'collapse' + index"
                  :aria-labelledby="'heading' + index"
                  data-bs-parent="#accordion"
                >
                  <div class="card-body card-body-text">
                    Сумма:
                    <span v-if="!item.isPoint" class="font-danger">
                      {{ Number(item.points).toLocaleString() }} ₽
                    </span>
                    <span
                      v-if="item.isAddPoint && item.isPoint"
                      class="font-success"
                    >
                      {{ Number(item.points).toLocaleString() }} ₽
                    </span>
                    <span
                      v-if="!item.isAddPoint && item.isPoint"
                      class="font-warning"
                    >
                      {{ Number(item.points).toLocaleString() }} ₽
                    </span>
                    <br />
                    {{ item.description }} <br />
                    Дата:
                    {{ new Date(item.created_at).toLocaleDateString("ru-Ru") }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AlertError from "@/components/alert-error.vue";
import AlertSuccess from "@/components/alert-succes.vue";
import MoneyDataService from "@/services/MoneyDataService";

export default {
  data() {
    return {
      errorMessage: "",
      successMessage: "",
      money: [],
      isCollapsed: {},
    };
  },
  components: {
    AlertSuccess,
    AlertError,
  },
  mounted() {
    MoneyDataService.history()
      .then((response) => {
        this.money = response.data;
        console.log("log", money);
      })
      .catch((e) => {
        console.log(e);
      });
  },
  methods: {
    toggleCollapse(index) {
      for (let key in this.isCollapsed) {
        if (key !== index) {
          this.isCollapsed[key] = false;
        }
      }
      this.isCollapsed[index] = !this.isCollapsed[index];
    }
  }
};
</script>

<style scoped>
.card-body-text {
  font-size: 15px !important;
}
h5 {
  position: relative;
}
.sidebar-toggles {
  position: absolute;
  right: 1%;
  top: 30%;
}
.rotate180 {
  transform: rotate(90deg);
  transition: all .3s;
}
</style>

<template>
  <li>
    <span class="web youtube">
      <a target="_blank" href="https://www.youtube.com/@rentierworld">
        <img src="@/assets/images/web/youtube.svg" alt="Icon" />
      </a>
    </span>
  </li>
  <li>
    <span class="web telegram">
      <a target="_blank" href="https://t.me/rentier_official">
        <img src="@/assets/images/web/telegram.svg" alt="Icon" />
      </a>
    </span>
  </li>
  <li>
    <span class="web">
      <a target="_blank" href="https://t.me/rentiernews">
        <vue-feather type="book-open"></vue-feather>
      </a>
    </span>
  </li>
  <li class="profile-nav onhover-dropdown pe-0 py-0">
    <div class="media profile-media">
      <img class="b-r-10" src="@/assets/images/dashboard/profile.png" alt="" />
      <div class="media-body">
        <span>{{ fio }}</span>
        <p class="mb-0 font-roboto">
          {{ rank }} <i class="middle fa fa-angle-down"></i>
        </p>
      </div>
    </div>
    <ul class="profile-dropdown onhover-show-div p-0">
      <li>
        <router-link :to="'/'"
          ><vue-feather type="user"></vue-feather
          ><span>ПРОФИЛЬ</span></router-link
        >
      </li>
      <li>
        <router-link :to="'/users/edit'"
        ><vue-feather type="edit-2"></vue-feather
        ><span>РЕДАТИРОВАТЬ ПРОФИЛЬ</span></router-link
        >
      </li>
      <li>
        <router-link :to="'/users/settings'"
        ><vue-feather type="settings"></vue-feather
        ><span>НАСТРОЙКИ</span></router-link
        >
      </li>
      <li>
        <a @click="logout"
          ><vue-feather type="log-in"></vue-feather><span>ВЫХОД</span></a
        >
      </li>
    </ul>
  </li>
</template>

<script>
import UserDataService from '@/services/UserDataService';

export default {
  name: "Profile",
  data() {
    return {
      fio: '',
      rank: '',
      telegram: '',
      email: '',
      dateRegister: ''
    };
  },
  mounted() {
    this.getUser();
  },
  methods: {
    logout: function () {
      this.$router.replace("/auth/login");
      localStorage.removeItem("User");
    },
    getUser() {
      UserDataService.get()
          .then(response => {
            const user = response.data;
            this.fio = user.lastName + ' ' + user.firstName;
            this.rank = (user.user.rank) ? user.user.rank.name : 'Ранг отсутствует';
            this.telegram = (user.telegram) ? user.telegram : 'отсутствует';
            this.email = user.user.email;
            this.dateRegister = new Date(user.created_at).toLocaleDateString('ru-Ru');
          })
          .catch(e => {
            console.log(e);
          });
    },
  },
};
</script>

<style scoped>

.profile-dropdown{
  font-size: 14px;
  font-weight: 400;
  line-height: 26px;
}

</style>

<template>
  <Breadcrumbs main="АДМИНИСТРАЦИЯ" title="ПОЛЬЗОВАТЕЛИ" />



  <div class="container-fluid wrapper-founders-users modal-realetive">
    <div class="row ">
      <div class="col-12 col-md-12 col-lg-5 col-xl-4 col-xxl-3">
        <div class="card d-flex justify-content-center h-100">
          <div class="card-item-left d-flex align-content-center justify-content-start p-3 h-100">
            <div :class="status === 1 ? 'controller active-status-first' : 'controller active-status-second'" class="d-flex justify-content-around height-bottom pb-3 mt-3">
              <div :class="status === 1 ? 'top-left-card-menu-item purple' : 'top-left-card-menu-item'" class="w-40 cursor-pointer" @click="filtersOnClick(1)">
                Фильтры
              </div>
              <div :class="status === 2 ? 'top-left-card-menu-item purple' : 'top-left-card-menu-item'" class="w-60 cursor-pointer" @click="filtersOnClick(2)">
                Поиск по имени
              </div>
            </div>
            <div class="left-card-menu">
              <div class="left-card-menu-filters pt-3" v-show="status === 1">
                <div class="top-filters mb-3">
                  <div class="wrapper-switch d-flex justify-content-around">
                    <div class="switch-input-single ps-3">
                      <input type="radio" class="btn-check" v-model="selectedOption" :value="true" name="options-base" id="option1" autocomplete="off" checked>
                      <label class="btn-switch m-0 p-2 cursor-pointer" for="option1">Активные</label>
                    </div>
                    <div class="switch-input-single ps-3">
                      <input type="radio" class="btn-check" v-model="selectedOption" :value="false" name="options-base" id="option2" autocomplete="off">
                      <label class="btn-switch m-0 p-2 cursor-pointer" for="option2">Неактивные</label>
                    </div>
                  </div>
                  <div class="d-flex align-items-center justify-content-start my-2">
                    <li class="profile-nav dropdown pe-0 py-0 d-flex align-items-center">

                      <label for="datetime" class="drop-down-btn placeholder-label m-0">
                        Период
                        <img src="@/assets/images/network/Arrow-white.svg" alt="">
                      </label>
                      <ul class="profile-dropdown show-div px-2 py-3 w-100">
                        <label for="datetime-start" class="placeholder-label m-0 w-100 pb-2">
                          Начало периода
                        </label>
                        <input
                            id="datetime"
                            class="form-control"
                            type="date"
                            v-model="selectedDateStart"
                            @focus="showPickerStart = true"
                            @blur="showPickerStart = false"
                        />

                        <label for="datetime-end" class="placeholder-label m-0 w-100 py-2">
                          Окончание периода
                        </label>

                        <input
                            id="datetime-end"
                            class="form-control"
                            type="date"
                            v-model="selectedDateEnd"
                            @focus="showPickerEnd = true"
                            @blur="showPickerEnd = false"
                        />
                      </ul>
                    </li>
                    <div class="drop-down-btn ms-2" @click="searchByFilter">
                      Поиск
                    </div>
                  </div>
                  <alert-error v-if="this.isErrorDate" :text="isErrorDate" />
                </div>
              </div>
              <div class="left-card-menu-searh pt-3 width-scroll" v-show="status === 2">
                <div class="input-container-seacrh pb-3">
                  <input class="form-control" type="text" placeholder="Введите почту" v-model="email" @input="onInput($event)">
                  <i class="fa fa-search"></i>
                </div>
              </div>
              <div class="search-spisok card-scroll" @scroll="handleScroll">
                <div v-if="!isLoadingList" class="spisok-sortirovki">
                  <div v-for="(letter, index) in alphabet" :key="index">
                    <h5 v-if="usersByLetter[letter].length > 0" class="pt-2">{{ letter }}</h5>
                    <div v-for="(item, userIndex) in usersByLetter[letter]" :key="userIndex" class="wrapper-user d-flex align-item-center cursor-pointer p-2" @click="getFullInfoUser(item.id, getAvatarStyle(userIndex))">
                      <div class="avatar-user">
                        <div class="avatar-circle" :style="getAvatarStyle(userIndex)">
        <span class="avatar-text">
          {{ item.profile?.fio ? item.profile.fio.charAt(0) : '?' }}
        </span>
                        </div>
                      </div>
                      <div class="brief-description d-flex flex-column align-item-center justify-content-center flex-grow-1 ps-3">
                        <div class="full-name">
                          {{ item.profile?.fio ? item.profile.fio : 'Отсутствует' }}
                        </div>
                        <div class="email">
                          {{ item.email ? item.email : 'Отсутствует' }}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div v-if="Object.values(usersByLetter).every(arr => arr.length === 0)" class="p-5 text-center">Не найдено</div>
                </div>
                <div v-else class="d-flex justify-content-center align-items-center w-100 h-100 p-5">
                  <div class="p-5">
                    <div class="loader-cover"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-12 col-md-12 col-lg-7 col-xl-8 col-xxl-9 mt-5 mt-sm-5 mt-md-5 mt-lg-0">
        <div class="card right-card-menu d-flex justify-content-between h-100">
          <div v-if="!isLoadingDetail && !isErrorDetail" class="users h-100 w-100">
            <div v-if="user" class="wrapper-user d-flex flex-column h-100">
              <div class="card-title d-flex justify-content-between align-items-center p-3 m-0">
                <h5 class="title">Пользователи</h5>
                <div class="card-title-net gray">
                  {{countOfNetwork ? countOfNetwork : '0'}} сетей
                </div>
              </div>
              <div :class="inspectorOpeningOfNetwork ? 'active' : ''" class="position-relative d-flex flex-grow-1">
                <div class="wrapper-profile position-relative">
                  <div class="wrapper-close-btn" @click="switchNetwork">
                    <div class="close-btn">

                    </div>
                  </div>
                  <div class="card-wrapper d-flex justify-content-between flex-grow-1">
                    <div class="card-profile d-flex align-items-center">
                      <div class="profile-avatar">
                        <div class="profile-avatar-item d-flex justify-content-center align-items-center" :style="activeColor">
                          {{ firstNameLetter }}
                        </div>
                      </div>
                      <div class="profile-des align-items-center">
                        <div class="profile-des-name">
                          <h5>{{ user.profile?.fio ? user.profile.fio : 'Отсутствует' }}</h5>
                        </div>
                        <div class="profile-des-email gray mb-2">
                          <span>{{ user.email }}</span>
                        </div>
                        <div class="profile-des-button-wrapper d-flex">
                          <div class="purple btn-border-right cursor-pointer pe-2" @click="openModalMessage">Написать</div>
                          <div class="purple cursor-pointer px-2" @click="switchNetwork">Сеть</div>
                        </div>
                      </div>
                    </div>
                    <div class="card-profile-right">
                      <div class="card-profile-right-role d-flex justify-content-between"></div>
                    </div>
                  </div>
                  <Contact :user="user"/>
                </div>
                <div class="wrapper-network">
                  <div class="wrapper-network-list w-100 h-100">
                    <div v-if="NetworkFirstLevel.length !== 0" class="network-scroll w-100 h-100">
                      <div v-for="(item, index) in NetworkFirstLevel" class="network-item cursor-pointer d-flex w-100 p-2">
                        <div class="me-2">
                          <div class="avatar-circle" :style="getAvatarStyle(index)">
                            <span class="avatar-text">{{ item.profile?.fio ? item.profile.fio.charAt(0) : '?' }}</span>
                          </div>
                        </div>
                        <div>
                          <div>{{ item.profile?.fio ? item.profile.fio : '?' }}</div>
                          <div>{{ item.email ? item.email : '?' }}</div>
                        </div>
                      </div>
                    </div>
                    <div v-else class="network-item-empty gray h-100 d-flex justify-content-center align-items-center">Пусто</div>
                  </div>
                </div>
              </div>
            </div>
            <div v-else class="wrapper-no-selected d-flex justify-content-center align-items-center h-100 w-100 p-5">
              <div class="p-5">
                <vue-feather
                    class="status_toggle middle sidebar-toggle me-1"
                    type="edit"
                ></vue-feather>
                Выберите пользователя для просмотра
              </div>
            </div>

          </div>
          <div v-else-if="isErrorDetail" class="d-flex justify-content-center align-items-center w-100 h-100 p-5">
            <div class="p-5">
              Что-то пошло не так...
            </div>
          </div>
          <div v-else class="d-flex justify-content-center align-items-center w-100 h-100 p-5">
            <div class="p-5">
              <div class="loader-cover"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div :class="isActiveModal ? `active` : ``" class="modal-wrapper">
      <div class="modal-overlay" @click="closeModal"></div>
      <div class="modal-content" >
        <div class="modal-message">
          <div class="modal-message-title d-flex justify-content-between align-items-center">
            <h3 class="modal-title-text">Предупреждение</h3>
            <vue-feather @click="closeModal" type="x" class="cursor-pointer" ></vue-feather>
          </div>
        </div>
        <div class="modal-send-message">
          <div class="modal-send-message-input d-flex justify-content-center aling-items-center">
            <label for="">
              Будет доступно в следующих обновлениях.
            </label>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import Contact from "@/components/founders/contact.vue";
import FoundersDataService from "@/services/FoundersDataService"
import alertError from "@/components/alert-error.vue";

export default {
  data() {
    return{
      status: 1,
      isActiveModal: false,
      alphabet: 'АБВГДЕЁЖЗИКЛМНОПРСТУФХЦЧШЩЬЮЯABCDEFGHIJKLMNOPQRSTUVWXYZ'.split(''),
      user: null,
      activeColor: null,
      isLoadingList: true,
      isLoadingDetail: false,
      isErrorDetail: false,
      isErrorDate: null,
      users: [],
      selectedOption: 1,
      selectedDateStart: null,
      selectedDateEnd: null,
      showPickerStart: false,
      showPickerEnd: false,
      email: '',
      timeout: null,
      avatarColors: [],
      countOfNetwork: null,
      NetworkFirstLevel: [],
      inspectorOpeningOfNetwork: false,
      isFirstLoad: true,
      email: '',
      page: 1,
      pageSize: 10,
      filterActive: false,
    }
  },
  components: {
    Contact,
    alertError,
  },
  mounted(){
    this.getUsers(this.page, this.pageSize)
  },
  computed: {
    firstNameLetter() {

      return this.user && this.user.profile && this.user.profile.fio ? this.user.profile.fio.charAt(0).toUpperCase() : "?";
    },
    usersByLetter() {
      const result = {};
      this.alphabet.forEach(letter => {
        result[letter] = this.users.filter(user => {
          return user.profile?.fio && user.profile.fio.toLowerCase().startsWith(letter.toLowerCase());
        }).sort((a, b) => {
          const aFio = a.profile?.fio?.toLowerCase() || '';
          const bFio = b.profile?.fio?.toLowerCase() || '';
          if (aFio < bFio) return -1;
          if (aFio > bFio) return 1;
          return 0;
        });

      });
      return result;
    },
  },

  methods: {
    handleScroll(event) {
      const element = event.target;

      if (element.scrollHeight - element.scrollTop <= element.clientHeight) {
        this.loadMoreUsers();
      }
    },
    loadMoreUsers() {
      let dateStart = null;
      let dateEnd = null;

      if (this.selectedDateStart) {
        dateStart = new Date(this.selectedDateStart).toISOString();
      }

      if (this.selectedDateEnd) {
        dateEnd = new Date(this.selectedDateEnd).toISOString();
      }

      this.page++;

      if (this.filterActive) {
        FoundersDataService.getUsersByFilters(this.page, this.pageSize, dateStart, dateEnd, this.selectedOption)
            .then((response) => {
              const newUsers = response.data.values;

              if (newUsers.length > 0) {
                this.users = [...this.users, ...newUsers];
              }
            })
            .catch((e) => {
              console.error(e);
            });
      } else if (this.email) {
        this.getUsersByEmail(this.page, this.pageSize, this.email, true);
      } else {
        this.getUsers(this.page, this.pageSize, true);
      }
    },

    getUsersByFilters(page, pageSize, startOfReg, endOfReg, isActivate) {
      return FoundersDataService.getUsersByFilters(page, pageSize, startOfReg, endOfReg, isActivate);
    },
    searchByFilter(){

      this.page = 1

      this.users = []

      let dateStart = null

      let dateEnd = null

      this.filterActive = true

      if (this.selectedDateStart) {

        dateStart = new Date(this.selectedDateStart).toISOString()

      }

      if (this.selectedDateEnd) {

        dateEnd = new Date(this.selectedDateEnd).toISOString()

      }

      this.getUsersByFilters(this.page, this.pageSize, dateStart, dateEnd, this.selectedOption)
    },
    getUsersByFilters(page, pageSize, startOfReg, endOfReg, isActivate, append = false) {

      this.isLoadingList = true

      FoundersDataService.getUsersByFilters(page, pageSize, startOfReg, endOfReg, isActivate)
          .then((response) => {
            const newUsers = response.data.values;

            this.isErrorDate = ''

            if (newUsers.length > 0) {

              if (append) {
                this.users = [...this.users, ...newUsers];
              } else {
                this.users = newUsers;
              }

            }

            setTimeout(() => {
              this.isLoadingList = false
            },1000)
          })
          .catch((e) => {
            if (e.response.status === 400) {

              this.isErrorDate = 'Должны быть заполнены обе даты'
            }
            setTimeout(() => {
              this.isLoadingList = false
            },1000)
            console.error(e);
          });
    },

    getFullInfoUser(userId, color){

      this.activeColor = color

      this.isLoadingDetail = true
      this.isErrorDetail = false
      FoundersDataService.getFullInfoUser(userId)
          .then((response) => {
            this.user = response.data.user
            this.countOfNetwork = response.data.countOfNetwork

          })
          .catch((e) => {
            console.log(e)
            setTimeout(() => {
              this.isLoadingDetail = false
              this.isErrorDetail = true
            }, 1000)
          })

      FoundersDataService.getNetworkFirstLevel(userId)
          .then((response) => {
            this.NetworkFirstLevel = response.data

            setTimeout(() => {
              this.isLoadingDetail = false
            }, 500)
          })
          .catch((e) => {
            console.log(e)
            setTimeout(() => {
              this.isLoadingDetail = false
              this.isErrorDetail = true
            }, 1000)
          })
    },

    getfirstNameLetter(index) {
      return this.users[parseInt(index)].profile?.fio.charAt(0).toUpperCase();
    },
    getUsers(page, pageSize, append = false) {

      FoundersDataService.getUsers(page, pageSize)
          .then((response) => {
            const newUsers = response.data.values;

            if (append) {
              this.users = [...this.users, ...newUsers];
            } else {
              this.users = newUsers;
            }

            if (this.isFirstLoad) {
              this.avatarColors = this.generateAvatarColors(this.users.length);
              this.isFirstLoad = false;
            }

            setTimeout(() => {
              this.isLoadingList = false
            },1000)
          })
          .catch((e) => {

            setTimeout(() => {
              this.isLoadingList = false
            },1000)
            console.error(e);
          });
    },
    getUsersByEmail(page, pageSize, email, append = false) {
      FoundersDataService.getUsersByEmail(page, pageSize, email)
          .then((response) => {
            const newUsers = response.data.values;

            if (append) {
              this.users = [...this.users, ...newUsers];

            } else {
              this.users = newUsers;
            }

            if (this.isFirstLoad) {
              this.avatarColors = this.generateAvatarColors(this.users.length);
            }

            setTimeout(() => {
              this.isLoadingList = false
            },1000)
          })
          .catch((e) => {
            setTimeout(() => {
              this.isLoadingList = false
            },1000)
            console.error(e);
          });
    },

    generateAvatarColors(count) {
      const colors = [];
      const letters = '0123456789ABCDEF';
      for (let i = 0; i < count; i++) {
        let color = '#';
        for (let j = 0; j < 6; j++) {
          color += letters[Math.floor(Math.random() * 16)];
        }
        colors.push(color);
      }

      return colors;
    },

    getAvatarStyle(index) {
      const colorIndex = index % this.avatarColors.length;
      return {
        'background-color': this.avatarColors[colorIndex]
      };
    },

    onInput(event) {

      if (this.timeout) {
        clearTimeout(this.timeout);
      }

      const inputValue = event.target.value;

      this.timeout = setTimeout(() => {

        this.isLoadingList = true

        this.filterActive = false

        if (inputValue){
          this.handleInputEnd(inputValue);
        } else {
          this.page = 1
          this.getUsers(this.page, this.pageSize, true)
        }
      }, 1000);
    },
    handleInputEnd(email) {
      this.page = 1
      this.email = email
      this.getUsersByEmail(this.page, this.pageSize, email)

    },
    closeModal(){
      this.isActiveModal = false;
    },
    openModalMessage(){
      this.isActiveModal = true
    },
    switchNetwork() {
      this.inspectorOpeningOfNetwork = !this.inspectorOpeningOfNetwork
    },
    truncateEmail(email) {
      if (email.length > 20) {
        return email.slice(0, 20) + '...';
      }
      return email;
    },
    minifirstNameLetter(user) {
      if (user && user.fio) {
        return user.fio.charAt(0).toUpperCase();
      } else {
        return '';
      }
    },
    openUser(id) {
      this.user = this.users.find(user => user.id === id) || {
        id: 1,
        fio: "Захар 1.",
        email: "zujuttauddoppa-2473@yopmail.com",
        role: "Тех.Поддежрка",
        countNetworks: 333,
        contacts: {
          mobileTel: '7-565-576-12-56',
          Ok: "https://ok.ru",
          Vk: "https://vk.com ",
          Tg: "web.telegram",
          Course: "Любитель",
          invitedUser: "Анастасия Д."
        }
      };
    },
    filtersOnClick(id){
      this.status = id
    }
  },
};
</script>
<template>
  <div class="userService_wrapper">
    <Breadcrumbs title="РЕКОМЕНДОВАТЬ"/>
    <div id="isser" class="container-fluid knowledgebase-page">
      <div class="row">


        <div class="col-sm-12">
          <div class="card">
            <div class="card-body">
              <p>В данном разделе Вы можете порекомендовать клиента. При заключении договора - Вы получите
                вознаграждение.</p>
            </div>
          </div>
        </div>

      </div>
    </div>

    <div class=" card form-send col-xl-8 shadow-lg">
      <p class="alert alert-warning modal-windows" v-if="errorMessage">{{ errorMessage }}</p>
      <p class="alert alert-success modal-windows" v-if="successMessage">{{ successMessage }}</p>
      <form class="">
        <div class="card-header">
          <h4 class="card-title mb-0">Рекомендовать клиента</h4>
          <div class="card-options">
            <a
                class="card-options-collapse"
                href="javascript:void(0)"
                data-bs-toggle="card-collapse"
            ><i class="fe fe-chevron-up"></i></a
            ><a
              class="card-options-remove"
              href="javascript:void(0)"
              data-bs-toggle="card-remove"
          ><i class="fe fe-x"></i
          ></a>
          </div>
        </div>
        <div class="card-body">
          <div class="row">
            <div class="col-md-5">
              <div class="mb-3">
                <label class="form-label">Фамилия</label>
                <input
                    required
                    v-model="formData.lastName"
                    class="form-control"
                    type="text"
                    placeholder="Фамилия"
                    :class="{ 'is-invalid': !formData.lastName && isTouched1 }"
                    @input="isTouched1 = true"
                />
                <div
                    v-if="!formData.lastName && isTouched1"
                    class="invalid-feedback"
                >
                  Поле должно быть заполнено
                </div>
              </div>
            </div>
            <div class="col-sm-6 col-md-3">
              <div class="mb-3">
                <label class="form-label">Имя</label>
                <input
                    required

                    v-model="formData.firstName"
                    class="form-control"
                    type="text"
                    placeholder="Имя"
                    :class="{ 'is-invalid': !formData.firstName && isTouched2 }"
                    @input="isTouched2 = true"
                />
                <div
                    v-if="!formData.firstName && isTouched2"
                    class="invalid-feedback"
                >
                  Поле должно быть заполнено
                </div>
              </div>
            </div>
            <div class="col-sm-6 col-md-4">
              <div class="mb-3">
                <label class="form-label">Отчество</label>
                <input
                    required
                    v-model="formData.middleName"
                    class="form-control"
                    type="text"
                    placeholder="Отчество"
                    :class="{ 'is-invalid': !formData.middleName && isTouched3 }"
                    @input="isTouched3 = true"
                />
                <div
                    v-if="!formData.middleName && isTouched3"
                    class="invalid-feedback"
                >
                  Поле должно быть заполнено
                </div>
              </div>
            </div>
            <div class="col-sm-6 col-md-6">
              <div class="mb-3">
                <!-- <label class="form-label">Номер телефона</label>
                <input class="form-control"
                      required
                      v-model="formData.phone"
                      type="text" :placeholder="servicesId.phone"
                      :class="{ 'is-invalid': !formData.phone && isTouched4 }"
                      @input="isTouched4 = true"/>
                <small>Формат +7 000 000 0000</small>
                <div
                    v-if="!formData.phone && isTouched4"
                    class="invalid-feedback"
                >
                  Поле должно быть заполнено
                </div> -->
                <div class="mb-3">
                  <label class="form-label">Телефон</label>
                  <input
                      required
                      v-model="formData.phone"
                      v-mask="'+0 000 000 0000'"
                      class="form-control"
                      type="tel"
                      placeholder="Телефон"
                      :class="{ 'is-invalid': !formData.phone && isTouched4 }"
                      @input="isTouched4 = true"
                  />
                  <small>Формат +7 XXX XXX XX XX</small>
                  <div
                      v-if="!formData.phone && isTouched4"
                      class="invalid-feedback"
                  >
                    Поле должно быть заполнено
                  </div>
                </div>
              </div>
            </div>
            <div class="col-sm-6 col-md-6">
              <label class="form-label">Категория</label>
              <select class="form-select" id="validationCustom04">
                <option value="0">ИТ-услуги</option>
                <option value="0">Бухгалтерские услуги</option>
                <option value="0">Юридические услуги</option>
              </select>
            </div>
            <div class="col-md-12">
              <div>
                <label class="form-label">Описание</label>
                <textarea
                    required
                    class="form-control"
                    rows="5"
                    placeholder="Описание"
                    v-model="formData.comment"
                    :class="{ 'is-invalid': !formData.comment && isTouched5 }"
                    @input="isTouched5 = true"
                ></textarea>
                <div
                    v-if="!formData.comment && isTouched5"
                    class="invalid-feedback"
                >
                  Поле должно быть заполнено
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
      <div class="card-footer text-end">
        <!-- <router-link
          class="btn btn btn-primary"
          to="#isser"
          @click="postSendServices"
          >Отправить</router-link
        > -->
        <button class="btn btn-outline-primary" @click="postSendServices" type="submit">Отправить</button>
      </div>
    </div>

    <div class="wrapper-table col-md-12">
      <div class="card">
        <div class="card-header">
          <h4 class="card-title mb-0">Все заявки</h4>
          <div class="card-options">
            <a
                class="card-options-collapse"
                href="#"
                data-bs-toggle="card-collapse"
            ><i class="fe fe-chevron-up"></i></a
            ><a
              class="card-options-remove"
              href="#"
              data-bs-toggle="card-remove"
          ><i class="fe fe-x"></i
          ></a>
          </div>
        </div>
        <div class="table-responsive add-project">
          <table class="table card-table table-vcenter text-nowrap">
            <thead>
            <tr>
              <th>Имя</th>
              <th>Дата</th>
              <th>Статус</th>
              <th>Цена</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(item, index) in servicesId" :key="index">
              <td>
                <tr>
                  {{ item.firstName }}
                </tr>
              </td>
              <td>
                <tr>
                  {{ formattedDate(item.created_at) }}
                </tr>
              </td>
              <td>
                <tr>{{ item.status.name }}</tr>
              </td>
              <td>
                <tr>
                  <span v-if="item.price>0">
                  {{ item.price }}
                    </span>
                  <span v-else> - </span>
                </tr>
              </td>
            </tr>
            </tbody>
          </table>
        </div>

      </div>
    </div>
  </div>
</template>
<script>
import ServiceITDataService from "@/services/ServiceITDataService";
import UserDataService from "@/services/UserDataService";
import moment from "moment";
import "moment/locale/ru";

export default {
  name: "userService",
  data() {
    return {
      errorMessage: '',
      successMessage: '',
      id: "",
      servicesId: [],
      formData: {
        firstName: "",
        lastName: "",
        middleName: "",
        phone: "",
        comment: "",
      },
    };
  },
  mounted() {
    this.getUser();
    this.getCurrent();
  },
  methods: {
    getUser() {
      UserDataService.get()
          .then(({data}) => {
            this.id = data.id;
          })
          .catch((error) => {
            console.log(error);
          });
    },
    putItService() {
      ServiceITDataService.putItService()
    },
    getCurrent(id) {
      ServiceITDataService.getCurrent(id)
          .then(({data}) => {
            this.servicesId = data.itServices;
          })
          .catch((e) => {
            console.log("ID ERROR", e);
          });
    },
    async postSendServices() {
      const requiredFields = document.querySelectorAll('input[required]');
      requiredFields.forEach(field => {
        if (!field.value) {
          // Если поле не заполнено, выделяем его красным
          field.classList.add('is-invalid');
        } else {
          // Если поле заполнено, удаляем класс is-invalid
          field.classList.remove('is-invalid');
        }
      });


      ServiceITDataService.postSendService(this.formData)
          .then(() => {
            this.successMessage = 'Заявление успешно добавленно.';
            this.getCurrent();
          })
          .catch((error) => {
            this.errorMessage = 'Пожалуйста, заполните все поля.';
            console.log(error);
          });

    },
    formattedDate(date) {
      moment.locale("ru");

      if (date === this.servicesId.created_at) {
        return moment(date).format("D MMMM HH:mm");
      } else {
        return moment(date).format("D MMMM HH:mm");
      }
    }
  },
};
</script>

<style scoped>
.wrapper-table {
  margin: 30px 10px 50px 5px;
}

.knowledgebase-page {
  display: flex;
  flex-direction: row;
  height: 100%;
  margin-top: 40px;
}

.userService_wrapper {
  display: flex;
  flex-direction: column;
}

.knowledgebase {
  display: flex;
  background: url(@/assets/images/knowledgebase/bg_1.jpg) center center/cover no-repeat;
}

@media (max-width: 768px) {
  .knowledgebase {
    display: flex;
    background: none;
  }
}

.form-send {
  margin: 20px auto;
}

.right_knowledgebase {
  height: 100%;
}

.user_form {
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-windows {
  border-radius: 10px;
}

.btn.btn-outline-primary:hover {
  background: var(--theme-deafult) !important;
  border-color: var(--theme-deafult) !important;
}
</style>


<template>
  <div class="wrapper-settings">
    <Breadcrumbs title="НАСТРОЙКИ" main="РАБОЧИЙ КАБИНЕТ" />
    <div class="wrapper-items-settings w-100 d-flex flex-column justify-content-center align-items-center">
      <div class="item-settings card wrapper-privacy w-100 m-3 p-4">
        <div class="item-settings-title mb-3">
          ПРИВАТНОСТЬ
        </div>
        <div class="item-settings-secondary row">
          <div class="item-settings-description col-12 col-md-6">
            Выводить Ваше ФИО, фото и контактную информацию другим пользователям
          </div>
          <div class="item-settings-choice d-flex flex-column col-12 col-md-6">
            <div class="container">
              <div class="radio">
                <input id="radio-1" @click="putSettingsChangePrivateToTrue" name="radio" type="radio" :checked="private">
                <label for="radio-1" class="radio-labels">Запрещено</label>
              </div>

              <div class="radio">
                <input id="radio-2" @click="putSettingsChangePrivateToFalse" name="radio" type="radio" :checked="!private">
                <label  for="radio-2" class="radio-labels">Разрешаю выводить вышестоящим</label>
              </div>

            </div>

          </div>
        </div>
      </div>
      <div class="item-settings card wrapper-auto w-100 m-3 p-4">
        <div class="item-settings-title mb-3">
          АВТОПОКУПКА
        </div>
        <div class="item-settings-secondary row">
          <div class="item-settings-description col-12 col-md-6">
            Автоматически списывать с основного счета сумму минимальной ежемесячной активности (1000 руб. на покупку сертификатов платформы "Рантье") при наличии средств.
          </div>
          <div class="item-settings-choice d-flex flex-column justify-content-center align-items-center col-12 col-md-6 p-5">
<!--            <label class="checkbox-green">-->
<!--              <input type="checkbox">-->
<!--              <span class="checkbox-green-switch" data-label-on="On" data-label-off="Off"></span>-->
<!--            </label>-->
            <label class="m-5"><input type="checkbox" id="scroll" v-model="autoPay" @input="requestSettingsSetAutoPay(autoPay)" /><b></b></label>
          </div>
        </div>
      </div>
      <div class="item-settings card wrapper-auto w-100 m-3 p-4">
        <div class="item-settings-title mb-3">
          ПРОМОКОДЫ
        </div>
        <div class="item-settings-secondary row">
          <div class="item-settings-description col-12 mb-3">
            <b>
              Сформируйте промокод для участника своей сети!
              <br>
              Промокод может быть использован только 1 раз и только одним пользователем.
            </b>
            <br>
              Срок действия промокода 90 дней со дня получения.
          </div>
          <div class="mb-3">
            <button
                v-if="this.user.isGeneratePromoCode"
                @click.prevent="generate"
                class="btn btn-outline-primary"
                type="button"
                data-original-title="btn btn-link btn-lg"
            >

              Сгенерировать
            </button>
          </div>
          <div>
            <select v-model="filterRank" class="form-select" @change="filter">
              <option disabled value="">Выберите статус</option>
              <option v-for="rank of ranks" :key="rank.id" :value="rank.id">
                {{ rank.name }}
              </option>
            </select>

            <table class="table tables table-hover">
              <thead>
              <tr>
                <th scope="col">Промо код</th>
                <th scope="col"></th>
                <th scope="col">Размер скидки, в %</th>
                <th scope="col">Для курса</th>
                <th scope="col">Активность</th>
                <th scope="col">Дата создания</th>
                <th scope="col">Дата окончания</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="promoCode of promoCodesFilter" :key="promoCode.id">
                <th scope="row">
                  <div>

                  </div>
                  {{ promoCode.code }}
                </th>
                <td class="">
                  <vue-feather
                      @click="clickCopyM(promoCode.code, index)"
                      class="ms-2 copy"
                      type="copy"
                  ></vue-feather>
                </td>
                <td>{{ promoCode.discountPercent }}%</td>
                <td>{{ promoCode.rank.name }}</td>
                <td>
                      <span class="font-success" v-if="!promoCode.isUsed"
                      >Не использован</span
                      >
                  <span class="font-danger" v-else>Использован</span>
                </td>
                <td>{{ formattedDate(promoCode.createdAt) }}</td>
                <td>{{ formattedDate(promoCode.createdAt) }}</td>
              </tr>
              </tbody>
            </table>
            <div class="default-according mt-5" id="accordion">
              <div
                  class="card"
                  v-for="(promoCode, index) of promoCodesFilter"
                  :key="promoCode.id"
              >
                <div class="card-header" :id="'heading' + index">
                  <h5 class="mb-0">
                    <button
                        class="btn btn-link wrapper"
                        data-bs-toggle="collapse"
                        :data-bs-target="'#collapse' + index"
                        aria-expanded="true"
                        :aria-controls="'collapseOne' + index"
                    >
                        <span class="accountType d-flex">
                          {{ promoCode.code }}
                          <vue-feather
                              @click="clickCopyM(promoCode.code, index)"
                              class="ms-2 copy"
                              type="copy"
                          ></vue-feather>
                          <div
                              class="copied ms-2"
                              v-if="showCopiedMessage[index]"
                          >
                            <p>Скопированно</p>
                          </div>
                        </span>
                      <vue-feather
                          class="arrow-down"
                          type="chevron-down"
                      ></vue-feather>
                    </button>
                  </h5>
                </div>
                <div
                    class="collapse"
                    :id="'collapse' + index"
                    :aria-labelledby="'heading' + index"
                    data-bs-parent="#accordion"
                >
                  <div class="card-body card-body-text">
                    <table class="table table-hover">
                      <tbody>
                      <tr>
                        <td><b> Размер скидки, в % : </b></td>
                        <td width="50%">
                          {{ promoCode.discountPercent }}%
                        </td>
                        <td></td>
                      </tr>
                      <tr>
                        <td><b> Для курса : </b></td>
                        <td width="50%">
                          {{ promoCode.rank.name }}
                        </td>
                        <td></td>
                      </tr>
                      <tr>
                        <td><b> Активность : </b></td>
                        <td width="50%">
                              <span
                                  class="font-success"
                                  v-if="!promoCode.isUsed"
                              >Не использован</span
                              >
                          <span class="font-danger" v-else
                          >Использован</span
                          >
                        </td>
                        <td></td>
                      </tr>
                      <tr>
                        <td><b> Дата создания : </b></td>
                        <td width="50%">
                          {{ formattedDate(promoCode.createdAt) }}
                        </td>
                        <td></td>
                      </tr>
                      <tr>
                        <td><b> Дата окончания : </b></td>
                        <td width="50%">
                          {{ formattedDate(promoCode.createdAt) }}
                        </td>
                        <td></td>
                      </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
<!--      <div class="item-settings card w-100 m-3 p-4">-->
<!--        <div class="item-settings-title mb-3">-->
<!--          КАК ПОЛУЧИТЬ КОД ПОДТВЕРЖДЕНИЯ-->
<!--        </div>-->
<!--        <div class="item-settings-secondary row">-->
<!--          <div class="item-settings-description col-12">-->
<!--            Автоматически списывать с основного счета сумму минимальной ежемесячной активности (1000 руб. на покупку сертификатов платформы "Рантье") при наличии средств.-->
<!--          </div>-->
<!--          <div class="item-settings-choice d-flex col-12 row">-->
<!--            <div class="checkbox col-6 col-md-3 d-flex justify-content-start align-items-center p-0">-->
<!--              <input id="checkbox1" type="checkbox" v-model="isPolitics.phone" @change="handleCheckboxChange('phone')">-->
<!--              <label class="text-muted ms-3" for="checkbox1">Номер телефона</label>-->
<!--            </div>-->
<!--            <div class="checkbox col-6 col-md-2 d-flex justify-content-start align-items-center p-0">-->
<!--              <input id="checkbox2" type="checkbox" v-model="isPolitics.email" @change="handleCheckboxChange('email')">-->
<!--              <label class="text-muted ms-3" for="checkbox2">Email</label>-->
<!--            </div>-->
<!--            <div class="checkbox col-6 col-md-2 d-flex justify-content-start align-items-center p-0">-->
<!--              <input id="checkbox3" type="checkbox" v-model="isPolitics.telegram" @change="handleCheckboxChange('telegram')">-->
<!--              <label class="text-muted ms-3" for="checkbox3">Telegram</label>-->
<!--            </div>-->
<!--            <div class="checkbox col-6 col-md-5 d-flex justify-content-start align-items-center p-0">-->
<!--              <input id="checkbox4" type="checkbox" v-model="isPolitics.sms" @change="handleCheckboxChange('sms')">-->
<!--              <label class="text-muted ms-3" for="checkbox4">SMS-бот</label>-->
<!--            </div>-->
<!--          </div>-->
<!--          <div v-if="isPolitics.phone" class="item-settings-choice d-flex col-12">-->
<!--            <div class="row w-100">-->

<!--              <div class="col-12 col-sm-9 my-4">-->
<!--                <label class="form-label">Номер телефона</label>-->
<!--                <input-->
<!--                    class="form-control me-3"-->
<!--                    v-mask="'+0 000 000 0000'"-->
<!--                    type="text"-->
<!--                    placeholder="Ваш телефон"-->
<!--                />-->
<!--              </div>-->
<!--              <div class="col-12 col-sm-3 my-4 d-flex align-items-end">-->
<!--                <div class="btn btn-primary">-->
<!--                  Сохранить-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->
<!--          <div v-if="isPolitics.email" class="item-settings-choice d-flex col-12">-->
<!--            <div class="row w-100">-->

<!--              <div class="col-12 col-sm-9 my-4">-->
<!--                <label class="form-label">Email</label>-->
<!--                <input class="form-control" type="email" required="" placeholder="Test@gmail.com"-->
<!--                       v-model="email">-->
<!--              </div>-->
<!--              <div class="col-12 col-sm-3 my-4 d-flex align-items-end">-->
<!--                <div class="btn btn-primary">-->
<!--                  Сохранить-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->
<!--          <div v-if="isPolitics.telegram" class="item-settings-choice d-flex col-12">-->
<!--            <div class="row w-100">-->

<!--              <div class="col-12 col-sm-9 my-4">-->
<!--                <label class="form-label">Telegram</label>-->
<!--                <input-->
<!--                    class="form-control"-->
<!--                    v-model="telegram"-->
<!--                    type="text"-->
<!--                    placeholder="@nick"-->
<!--                />-->
<!--              </div>-->
<!--              <div class="col-12 col-sm-3 my-4 d-flex align-items-end">-->
<!--                <div class="btn btn-primary">-->
<!--                  Сохранить-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->
<!--          <div v-if="isPolitics.sms" class="item-settings-choice d-flex col-12">-->
<!--            <div class="row w-100">-->

<!--              <div class="col-12 col-sm-9 my-4">-->
<!--                <label class="form-label">Номер телефона</label>-->
<!--                <input-->
<!--                    class="form-control me-3"-->
<!--                    v-mask="'+0 000 000 0000'"-->
<!--                    type="text"-->
<!--                    placeholder="Ваш телефон"-->
<!--                />-->
<!--              </div>-->
<!--              <div class="col-12 col-sm-3 my-4 d-flex align-items-end">-->
<!--                <div class="btn btn-primary">-->
<!--                  Сохранить-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
    </div>
  </div>
</template>

<script>
import AlertSuccess from "@/components/alert-succes.vue";
import alertError from "@/components/alert-error.vue";
import OperationsDataService from "@/services/OperationsDataService";
import moment from "moment/moment";
import MoneyDataService from "@/services/MoneyDataService";
import RanksDataService from "@/services/RanksDataService";
import SubscriptionDataService from "@/services/SubscriptionDataService";
import UserDataService from "@/services/UserDataService";
import PromoCodeDataService from "@/services/PromoCodeDataService";

export default {
  data() {
    return {
      ranks: [],
      isActivate: false,
      nextRank: {id: 1},
      errorMessage: "",
      successMessage: "",
      user: {isGeneratePromoCode: false},
      promoCodes: [],
      promoCodesFilter: [],
      filterRank: "",
      isPromo: false,
      isAmountAccount: false,
      idSystemPayment: 0,
      isPromoCodeActivate: null,
      isCode: false,
      private: localStorage.getItem('private'),
      autoPay: null,
      phone: "",
      email: "",
      telegram: "",
      showCopiedMessage: [],
      validate: {
        id: Number,
        code: "",
      },
      paymentData: {
        name: "Оплата курса - ",
        summa: 0,
        typePayment: 2,
        isPromoCode: false,
        promoCode: "",
        isAmountAccount: false,
      },
      isPolitics: {
        phone: true,
        email: false,
        telegram: false,
        sms: false
      },
      status: {
        ok: '<i class="font-success d-flex justify-content-center align-items-center" data-feather="alert-triangle"><i data-name="check-circle" data-tags="" data-type="check-circle" class="vue-feather vue-feather--check-circle"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-check-circle vue-feather__content"><path d="M22 11.08V12a10 10 0 1 1-5.93-9.14"></path><polyline points="22 4 12 14.01 9 11.01"></polyline></svg></i></i><span class="font-success"></span>',
        current:
            '<i class="font-roboto d-flex justify-content-center align-items-center" data-feather="alert-triangle"><i data-name="alert-circle" data-tags="warning,alert,danger" data-type="alert-circle" class="vue-feather vue-feather--alert-circle"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-alert-circle vue-feather__content"><circle cx="12" cy="12" r="10"></circle><line x1="12" y1="8" x2="12" y2="12"></line><line x1="12" y1="16" x2="12.01" y2="16"></line></svg></i></i><span class="font-roboto">Текущая</span>',
        next: '<i class="font-primary" data-feather="alert-triangle"><i data-name="check-circle" data-tags="" data-type="check-circle" class="vue-feather vue-feather--check-circle"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-check-circle vue-feather__content"><path d="M22 11.08V12a10 10 0 1 1-5.93-9.14"></path><polyline points="22 4 12 14.01 9 11.01"></polyline></svg></i></i><span class="font-primary">Доступна</span>',
        no: '<i class="font-danger" data-feather="alert-triangle"><i data-name="alert-triangle" data-tags="warning,alert,danger" data-type="alert-triangle" class="vue-feather vue-feather--alert-triangle"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-alert-triangle vue-feather__content"><path d="M10.29 3.86L1.82 18a2 2 0 0 0 1.71 3h16.94a2 2 0 0 0 1.71-3L13.71 3.86a2 2 0 0 0-3.42 0z"></path><line x1="12" y1="9" x2="12" y2="13"></line><line x1="12" y1="17" x2="12.01" y2="17"></line></svg></i></i><span class="font-danger">Не доступна</span>',
      },
    };
  },
  components: {
    AlertSuccess,
    alertError,
  },
  watch: {
    // autoPay(newVal) {
    //   this.requestSettingsSetAutoPay(newVal)
    // },
    private(newVal) {
      localStorage.setItem('private', newVal)
    }
  },
  mounted() {
    this.getSettings();
    this.getData();
  },
  methods: {
    format: (val) => `${val}`.replace(/(\d)(?=(\d{3})+([^\d]|$))/g, "$1 "),
    getSettings(){
      UserDataService.getSettings()
          .then((response) => {
            console.log("egeg", this.autoPay)
            if (response.data[1]){
              this.autoPay = response.data[1].isEnable;
            }
            if (response.data[0]){
              this.private = response.data[0].isEnable;
            }
          })
          .catch((e) => {
            console.log(e)
          })
    },
    requestSettingsSetAutoPay(value){
      try {
        console.log(value)
        if (value) {
          UserDataService.putSettingsSetAutoPay(false)
              .then(() => {

                  }
              )
              .catch((e) => {
                console.log(e)
              })
        }
        else {
          UserDataService.putSettingsSetAutoPay(true)
              .then(() => {

                  }
              )
              .catch((e) => {
                console.log(e)
              })
        }
      }
      catch (e){
        console.log(e)
      }
    },
    putSettingsChangePrivateToTrue(){
      UserDataService.putSettingsChangePrivate( true )
          .then((response) => {
            if(response.success === true){
              this.private = true
            }
          })
          .catch((e) => {
            console.log(e)
          })
    },
    putSettingsChangePrivateToFalse(){
      UserDataService.putSettingsChangePrivate( false )
          .then((response) => {
            if(response.success === true){
              this.private = false
            }
          })
          .catch((e) => {
            console.log(e)
          })
    },
    clickPayment() {
      this.isCode = true;

      OperationsDataService.get({typeOperation: 2}).then((response) => {
        this.validate.id = response.data;
      });
    },
    clickCopyM(text, index) {
      const input = document.createElement("input");
      input.setAttribute("value", text);
      document.body.appendChild(input);
      input.select();
      document.execCommand("copy");
      document.body.removeChild(input);

      this.showCopiedMessage[index] = true;
      setTimeout(() => {
        this.showCopiedMessage[index] = false;
      }, 3000);
    },
    formattedDate(date) {
      moment.locale("ru");
      return moment(date).format("D MMMM HH:mm");
      // if (date === this.event.meetingDateStart) {
      //   return moment(date).format("D MMMM HH:mm");
      // } else {
      //   return moment(date).format("D MMMM HH:mm");
      // }
      // if (date === this.event.meetingDateEnd) {
      //   return moment(date).format("D MMMM HH:mm");
      // } else {
      //   return moment(date).format("D MMMM HH:mm");
      // }
    },
    codeValidate() {
      OperationsDataService.codeValidate(this.validate).then((response) => {
        const isValidate = response.data;
        if (isValidate) this.choiceAcquiring();
        else
          this.errorMessage =
              "Не верный код. Попробуйте ввести заново, или обновите страницу и сгенерируйте новый.";
      });
    },
    choiceAcquiring() {
      if (this.isAmountAccount) this.payment();
      if (this.idSystemPayment === 0 && !this.isAmountAccount)
        window.location.href = "/my/payments/cloudpayments";
      if (this.idSystemPayment === 1 && !this.isAmountAccount) this.payment();
    },

    payment() {
      MoneyDataService.payment({
        typePayment: 2,
        selectPaymentSystem: 1,
        summa: 0,
        promoCode: this.paymentData.promoCode,
        isAmountAccount: this.isAmountAccount,
      })
          .then((response) => {
            if (this.isAmountAccount)
              this.successMessage = "Благодарим за покупку.";
            else {
              window.location.href = response.data.url;
              this.successMessage = "Перейдите на сайт банка для оплаты ранга.";
            }
          })
          .catch((e) => {
            this.errorMessage = "Ошибка оплаты. " + e.response.data.message;
            console.log(e);
          });
    },
    getData() {
      MoneyDataService.getIdPaymentService()
          .then((response) => {
            this.idSystemPayment = Number(response.data.idSystemPayment);
          })
          .catch((e) => {
            console.log(e);
          });

      RanksDataService.get()
          .then((response) => {
            this.ranks = response.data;
          })
          .catch((e) => {
            this.errorMessage = "Ошибка получения данных";
            console.log(e);
          });

      SubscriptionDataService.activate()
          .then((response) => {
            this.isActivate = response.data;

            if (!this.isActivate)
              this.errorMessage =
                  "Чтобы получить доступ к курсам, оплатите в начале подписку.";
          })
          .then(() => {
            if (this.isActivate)
              RanksDataService.getNextRank()
                  .then((response) => {
                    if (!response.data && response.data !== null)
                      this.nextRank.id = this.ranks.length + 1;
                    else this.nextRank = response.data;

                    this.paymentData.summa = this.nextRank.price;
                    this.paymentData.name += this.nextRank.name;
                    localStorage.setItem(
                        "paymentData",
                        JSON.stringify(this.paymentData)
                    );
                  })
                  .catch((e) => {
                    this.errorMessage = "Ошибка получения данных";
                    console.log(e);
                  });
          })
          .catch((e) => {
            console.log(e);
          });

      UserDataService.get()
          .then((response) => {
            this.user = response.data.user;
          })
          .catch((e) => {
            console.log(e);
          });

      PromoCodeDataService.get()
          .then((response) => {
            this.promoCodes = response.data;
            this.promoCodesFilter = this.promoCodes;
          })
          .catch((e) => {
            console.log(e);
          });
    },
    filter(event) {
      const id = event.target.value;
      let promoCodes = [];

      for (const promoCode of this.promoCodes)
        if (promoCode.rank.id === Number(id)) promoCodes.push(promoCode);

      this.promoCodesFilter = promoCodes;
    },
    generate() {
      PromoCodeDataService.generate()
          .then((response) => {
            this.promoCodes = response.data;
            this.promoCodesFilter = this.promoCodes;
            this.successMessage = "Промокоды успешно сгенерированы.";
          })
          .catch((e) => {
            console.log(e);
            this.errorMessage = "Ошибка генерации промокодов";
          });
    },
    checkPromo(event) {
      const promoCode = (this.name = event.target.value.toUpperCase());
      if (promoCode.length === 36)
        PromoCodeDataService.isPromoCode(promoCode)
            .then((response) => {
              this.isPromoCodeActivate = response.data;
              if (this.isPromoCodeActivate) {
                this.paymentData.isPromoCode = true;
                this.paymentData.promoCode = promoCode;
              }
              localStorage.setItem(
                  "paymentData",
                  JSON.stringify(this.paymentData)
              );
            })
            .catch(() => {
              this.errorMessage = "Ошибка проверки промокода";
            });
    },
    checkBox(type) {
      if (type === 1) {
        this.isPromo = false;
      }
      if (type === 2) {
        this.isAmountAccount = false;
      }
    },
    handleCheckboxChange(checkedBox) {
      for (let key in this.isPolitics) {
        if (key !== checkedBox) {
          this.isPolitics[key] = false;
        }
      }
    }
  },
};
</script>

<style scoped>

</style>
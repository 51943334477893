<template>
  <div>
    <Breadcrumbs title="ВЕРИФИКАЦИЯ" />
    <div class="container-fluid">
      <h3>Список заявок</h3>
      <div class="edit-profile">
        <div class="row">
          <div class="col-xl-4">
            <div class="card  email-sidebar md-sidebar shadow-lg">
              <div
                class="email-left-aside md-sidebar-aside"
                :class="filtered ? 'open' : ''"
              >
                <div class="">
                  <div class="card-body">
                    <div
                      class="email-app-sidebar left-bookmark custom-scrollbar"
                    >
                      <ul class="nav main-menu">
                        <li
                            class="nav-item"
                            :class="{ 'nav-item-open': openIndex === 3 }"
                            @click="checkFirstIndex(3)"
                        >
                          <a href="javascript:void(0)">
                            <span class="iconbg badge-light-primary"></span>
                            <span class="title">Все заявки</span>
                          </a>
                        </li>
                        <li
                          v-for="(item, index) in statuses"
                          :key="index"
                          class="nav-item"
                          :class="{ 'nav-item-open': openIndex === index }"
                          @click="toggleOpen(index)"
                        >
                          <a href="javascript:void(0)">
                            <span class="iconbg badge-light-primary"></span>
                            <span class="title">{{ item.name }}</span>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <listVerification :activePage="activePage" :openIndex="openIndex" :pages="pages" :verifications="verifications" :getVerifications="getVerifications" :statusActive="statusActive" :pageSize="pageSize"/>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import listVerification from "./listVerification.vue";
import VerificarionsDataService from "@/services/VerificarionsDataService";
export default {
  components: {
    listVerification,
  },
  data() {
      return {
        statuses: null,
        openIndex: null,
        statusActive: 0,
        pageSize: 10,
        verifications: null,
        pages: null,
        openIndex: null,
        activePage: null,
      }
    },
    methods: {
      checkFirstIndex(index){
        this.openIndex = index;
        localStorage.setItem('openIndex', index)
        this.getVerificationsDefault(this.pageSize);
      },
    getStatuses(){
      VerificarionsDataService.getStatuses()
      .then(({ data }) => {
          this.statuses = data;
        })
      .catch(e => {
        console.log(e);
        }) 
    },
    getVerificationsDefault(pageSize){
      VerificarionsDataService.getVerificationsDefault(pageSize)
      .then(({ data }) => {
          this.openIndex = 3
          this.verifications = data.requests;
          this.pages = Math.ceil(data.countRequests / pageSize)
        })
      .catch(e => {
        console.log(e);}) 
    },
    getVerifications(status, pageIndex, pageSize){
      this.activePage = pageIndex
      VerificarionsDataService.getVerifications(status,pageIndex,pageSize)
      .then(({ data }) => {
          this.verifications = data.requests;
          this.pages = Math.ceil(data.countRequests / pageSize)
        })
      .catch(e => {
        if (e.response.status === 500){

        } else {
          this.verifications = ''
        }
        }) 
    },
    toggleOpen(index) {
      if (this.openIndex === index) {
        this.openIndex = null;
        localStorage.removeItem('openIndex');
      } else {
        this.openIndex = index;
        localStorage.setItem('openIndex', index);
        this.statusActive = index + 1;
        this.getVerifications(index + 1, 0 ,this.pageSize);
      }
    },
  },
  mounted() {
    this.getStatuses();
    const savedOpenIndex = localStorage.getItem('openIndex');
    if (savedOpenIndex !== null) {
      this.openIndex = parseInt(savedOpenIndex, 10);
      if (this.openIndex === 3) {
        this.getVerificationsDefault(this.pageSize);
      } else {
        this.statusActive = this.openIndex + 1;
        this.getVerifications(this.statusActive, 0, this.pageSize);
      }
    } else {
      this.getVerificationsDefault(this.pageSize);
    }
  }
};
</script>
<style scoped>
h3 {
  padding: 30px;
}
.ctaservice_user {
  display: flex;
  justify-content: center;
}
.todolist {
  width: 100%;
  background: #7366ff;
  color: white;
}
.main-menu {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 10px 0;
  border-top: 1px solid rgba(82, 82, 108, 0.8);
}
.nav-item {
  position: relative;
  display: block;
  width: 100%;
  padding: 10px 0;
}
.time {
  margin-left: 10px;
}
.nav-item::after {
  position: absolute;
  content: "";
  width: 4px;
  height: 35px;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  background-color: #7366ff;
  border-radius: 5px 0px 0px 5px;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s;
}
.nav-item-open::after {
  opacity: 1;
  visibility: visible;
}
.nav-item .title {
  color: rgba(82, 82, 108, 0.8);
}
.todo-item {
  padding: 10px auto;
  width: 100%;
}
.todo-item a {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.todo-item a {
  color: rgba(82, 82, 108, 0.8);
}
.nav-item-open .title {
  color: #7366ff;
}
.user-wrapper {
  margin-bottom: 10px;
}
.pagination-primary {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}
</style>

<template>
  <div class="wrapper-modal d-flex justify-content-center align-items-center flex-column">

    <div class="backgroundImg">

      <img src="../../assets/images/network/BackgroundModal.svg" alt="back">
    </div>

    <div  class="avatar-circle" v-if="fio">
      <!--                          v-if="item.profile.avatar === null"-->
      {{ getFirstLetter(fio) }}
    </div>
    <div class="fio">
      {{ fio || 'Не указан' }}
    </div>
    <div class="rank">
      <img src="../../assets/images/network/LvlNetworkModal.svg" class="item-accordion-images-two" alt="">
      {{ rank }}
    </div>
    <div class="email mt-4">
      <div class="email-title d-flex justify-content-center align-items-center gap-1">
        <img src="../../assets/images/network/Email.svg" alt="">
        Email
      </div>
      <div class="email-desc mt-2">{{ email || 'Не указан' }}</div>

    </div>
    <div class="tel mt-3">
      <div class="tel-title d-flex justify-content-center align-items-center gap-1">
        <img src="../../assets/images/network/Tel.svg" alt="">
        Телефон
      </div>
      <div class="tel-desc mt-2" >{{ phone || 'Не указан' }}</div>
    </div>
    <div class="button-msg mt-5" @click="msg()">
      Написать сообщение
    </div>
  </div>
</template>
<script>
export default {
  data(){
    return{

    }
  },
  methods: {
    msg(){
      alert("Будет доступно в обновлении")
    },
    getFirstLetter(fullName) {
      return fullName ? fullName.charAt(0).toUpperCase() : '';
    },
  },
  props:{
    // backgroundImage:{
    //   type:String,
    //   required: true,
    // },
    fio:{
      type:String,
      required: true,
    },
    email: {
      type:String,
      required: true,
    },
    phone:{
      type:String,
      required: true,
    },
    rank:{
      type:String,
      required: true,
    },
    id:{
      type: Number,
      required: true,
    }
  }
}
</script>

<style scoped>

.rank{
  color: rgba(89, 102, 122, 1);
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
}
.avatar-circle {
  width: 112px;
  height: 112px;
  border-radius: 50%;
  background-color: #7366FF;
  color: white;
  border: 10px solid white;
  display: flex;
  position: absolute;
  top: 80px;
  justify-content: center;
  align-items: center;
  font-size: 20px;
}
.email-desc{
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
  color: rgba(33, 37, 41, 1);
}
.tel-title, .email-title{
  font-size: 17px;
  font-weight: 400;
  line-height: 14px;
  text-align: left;
  color: rgba(89, 102, 122, 1);
}
.fio{
  margin-top: 60px;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
}
.button-msg{
  padding: 7px 82px;
  background: rgba(115, 102, 255, 1);
  border-radius: 6px;
  color: rgba(255, 255, 255, 1);
  font-weight: 400;
  margin-bottom: 48px;
  @media screen and (max-width: 374px){
    padding: 7px 50px;
  }
}
</style>
<template>
  <div>
    <Breadcrumbs title="МОИ КУРСЫ" />
    <div class="container-fluid">
      <p>Раздел обновляется …</p>

      <div class="row" v-for="(course, index) in courses" :index="index" v-bind:key="course">
        <div class="col-sm-12">
          <div class="card">
            <div @click="toggleAccordion(index)" class="card-header">
              <div class="row">
                <div class="col">
                  <h5>{{ course.name }}</h5>
                </div>
                <div class="col-auto">
                  <template v-if="course.isOpen">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                      class="bi bi-caret-down-fill" viewBox="0 0 16 16">
                      <path
                        d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                    </svg>
                  </template>
                  <template v-else>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                      class="bi bi-caret-up-fill" viewBox="0 0 16 16">
                      <path
                        d="m7.247 4.86-4.796 5.481c-.566.647-.106 1.659.753 1.659h9.592a1 1 0 0 0 .753-1.659l-4.796-5.48a1 1 0 0 0-1.506 0z" />
                    </svg>
                  </template>
                </div>
              </div>

            </div>
            <div v-if="course.isOpen" class="gallery my-gallery card-body row cursor-pointer">
              <div v-if="course.test !== null" class="w-100 d-flex aling-items-center justify-content-end my-3">
                <div v-if="course.test.testsUserLog.length > 0" class="btn btn-success"
                  data-bs-target="#exampleModalToggle" data-bs-toggle="modal" @click="getResultTest(course.test.id)">
                  Результаты</div>
                <router-link v-else class="btn btn-primary" :to="'/tests/user/test/' + course.test.id">Пройти
                  тест</router-link>
              </div>
            </div>
            <div v-if="course.isOpen" class="gallery my-gallery card-body row" itemscope=""
              v-for="(module, index) in course.coursesModules" :index="index">
              <h6>{{ module.name }}</h6>


              <div class="col-md-6 col-xxl-4 box-col-4" v-for="(video, index) in module.video">
                <div class="card">
                  <div class="blog-box blog-grid text-center">
                    <div class="blog-wrraper">

                      <video class="p-3" controls controlsList="nodownload" style="height: 100%; width: 100%;"
                        preload="none" poster="../../../assets/images/video/de7efef480b19794da214f40b0f7f359.png">
                        <source :src="'https://rentier.world/api/videos/video/' + video.id" data-fluid-hd=""
                          :type="video.mimetype" />
                      </video>

                      <!-- <video-player ref="videoPlayer" controls>
                        <source :src="'https://rentier.world/api/videos/video/' + video.id" type="video/mp4" />
                      </video-player>
                      <select v-model="selectedQuality">
                        <option v-for="quality in qualities" :key="quality.value" :value="quality.value">
                          {{ quality.label }}
                        </option>
                      </select> -->

                    </div>
                    <div class="blog-details-main">
                      <h6 class="blog-bottom-details">
                        {{ index + 1 }}. {{ video.name }}
                      </h6>
                    </div>
                  </div>
                </div>
              </div>
              <div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal fade" id="exampleModalToggle" aria-hidden="true" aria-labelledby="exampleModalToggleLabel"
      tabindex="-1">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header d-flex justify-content-center align-items-center">
            <h1 class="modal-title fs-5" id="exampleModalToggleLabel">
              ИСТОРИЯ ПОПЫТОК
            </h1>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Закрыть"></button>
          </div>
          <div class="modal-body">
            <div v-for="(item, index) in testHistory" :key="index" class="item d-flex w-100 justify-content-between">
              <div class="data-item d-flex flex-column">
                <div>
                  <div> <b> {{ formatDate(item.created_at).date }} </b> </div>
                  <div>{{ formatDate(item.created_at).time }}</div>
                </div>
              </div>
              <div class="result-test d-flex justify-content-center align-items-center"
                :class="(item.isPassed) ? 'success' : 'danger'">
                <p class="mx-1"> {{ item.result }} </p>
                <p> из </p>
                <p class="mx-1"> {{ item.numberOfCorrectAnswers }} </p>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <div class="row">
              <div class="col-sm-12 col-md-6 col-xl-6 d-flex justify-content-center align-items-center mt-3">
                <button class="btn btn-primary" data-bs-dismiss="modal" aria-label="Закрыть">
                  Ок
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import alertError from "@/components/alert-error.vue";
import AlertSuccess from "@/components/alert-succes.vue";
import CoursesDataService from "@/services/CoursesDataService";
import TestingDataService from '@/services/TestingDataService';

export default {
  data() {
    return {
      errorMessage: "",
      successMessage: "",
      courses: [],
      testHistory: [],
      testsUserLog: null,
      qualities: [ // Доступные качества видео
        { label: '720p' },
        { label: '480p' }
      ]
    };
  },
  components: {
    AlertSuccess,
    alertError,
  },
  mounted() {
    this.getVideos();
  },
  methods: {
    getVideos() {
      CoursesDataService.get()
        .then(({ data }) => {
          console.log(data);
          this.courses = data.map(module => ({ ...module, isOpen: false }));
          this.testsUserLog = this.courses.length
        });
    },
    formatDate(dateTimeString) {
      const dateTime = new Date(dateTimeString);

      const months = ['января', 'февраля', 'марта', 'апреля', 'мая', 'июня', 'июля', 'августа', 'сентября', 'октября', 'ноября', 'декабря'];

      const date = `${dateTime.getDate()} ${months[dateTime.getMonth()]}`;

      const time = dateTime.toLocaleTimeString(undefined, { hour: 'numeric', minute: 'numeric' });

      return { date, time };
    },
    toggleAccordion(index) {
      this.courses[index].isOpen = !this.courses[index].isOpen;
    },
    getResultTest(testId) {
      console.log(testId);
      TestingDataService.getResultTest(testId)
        .then(({ data }) => {
          this.testHistory = data
          console.log(this.testHistory);
        });
    }
  },
};
</script>
<style scoped>
.modal-body {
  max-height: 500px;
  overflow: auto;
}

.success {
  color: #54ba4a;
}

.danger {
  color: #fc4438;
}
</style>

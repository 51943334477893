<template>
  <Breadcrumbs main="ПРОЕКТЫ" title="МОИ СЕРТИФИКАТЫ"/>
  <div class="card">
    <div class="card-header pb-0">
      <h5>КУПИТЬ СЕРТИФИКАТ</h5>
    </div>
    <alert-error v-if="errorMessageOne" :text="errorMessageOne" class="top-right-alert"/>
    <alert-success v-if="successMessageOne" :text="successMessageOne" class="top-right-alert"/>

    <div class="card-body">
      <form class="needs-validation">
        <div class="row g-3">
          <div class="col-md-4">
            <label class="form-label">Сумма</label>
            <input type="number" class="form-control" v-model="summaPayment" min="1000" @input="updateCertificateCount" :disabled="isCode" :max="countCertificateAvailable * certificatePrice" placeholder="Сумма сертификата">
          </div>
          <div class="col-md-2">
            <label class="form-label"   for="validationCustom02">Количество</label>
            <input class="form-control" :disabled="isCode" type="number" v-model="certificateCount" @input="updateSummaPayment" placeholder="Количество">
          </div>

          <div class="col-md-4">
            <label class="form-label" for="validationCustom02">Цена сертификата, ₽</label>
            <input class="form-control" type="number" :value="certificatePrice" placeholder="Количество" disabled>
          </div>
        </div>
        <div class="row mt-3 mb-3" v-if="idSystemPayment < 3">
          <div class="form-inline">
            <div class="m-2">
              <button class="btn btn btn-primary" type="button" v-if="!isCode" @click="clickPayment">
                Купить
              </button>
            </div>
            <div class="mt-2 form-check">
              <input type="checkbox" class="form-check-input" id="isAmountAccount" @change="checkBox"
                     v-model="isAmountAccount" :disabled="isCode">
              <label class="form-check-label" for="isAmountAccount">Оплатить 100% цены сертификата с внутреннего счёта.
                <a href="../documents/internal-credit" target="_blank" v-if="isAmountAccount">Заявление
                  на зачет.</a></label>
            </div>
          </div>
          <codeConfirmation :isCode=isCode :validate=validate :isAmountAccount=isAmountAccount
                            :idSystemPayment=idSystemPayment :paymentData=paymentData :typePayment=3 />
        </div>
        <hr>

        <p>Нажимая "Купить", я акцептирую (подписываю) <a href="https://rentier.world/opcion.pdf"
                                                          target="_blank">непубличную оферту</a> и <a
            href="https://rentier.world/pravila.pdf" target="_blank">Правила
          использования сертификатов</a>,
          направленные мне Компанией, путём введения одноразового кода подтверждения.</p>
      </form>
    </div>
  </div>


  <div class="card">
    <div class="card-header pb-0">
      <h5>ПЕРЕДАТЬ СЕРТИФИКАТ</h5>
    </div>
    <alert-error v-if="errorMessage" :text="errorMessage" class="top-right-alert"/>
    <alert-success v-if="successMessage" :text="successMessage" class="top-right-alert"/>
    <div class="card-body">
      <div class="row p-3">
        <p><b> Для успешного перевода Сертификата Стороны должны быть верифицированы в системе с заполнением паспортных
          данных. </b></p>

        <div class="col-sm-6 mt-1 col-lg-3">
          <input type="text" class="form-control" placeholder="Получатель, email" :disabled="isCodeTransfer"
                 v-model="transfer.email">
        </div>
        <div class="col-sm-6 mt-1 col-lg-3">
          <input type="Number" min="1" max="50000" class="form-control" placeholder="Количество"
                 :disabled="isCodeTransfer" v-model="transfer.count">
        </div>

        <div class="col-sm-6 mt-1 col-lg-3">
          <button class="btn btn-primary" type="button" @click="clickTransfer"
                  data-original-title="btn btn-link btn-lg"> Передать сертификаты
          </button>
        </div>

        <div class="form-inline row p-3 pb-0" v-if="isCodeTransfer">
          <div class="row">
            <div class="col-lg-3 col-sm-6">
              <input type="text" class="form-control" id="isCode" placeholder="Код" v-model="validateTransfer.code">
            </div>
            <button class="btn btn btn-primary col-lg-3 col-sm-6 " type="button"
                    :disabled="this.isSendTransfer || this.validateTransfer.code.length < 3"
                    @click="codeValidateTransfer">
              Подтвердить операцию
            </button>
          </div>
          <p class="font-warning">Для подтверждения операции и подписания <a href="../documents/certificate-transfer"
                                                                             target="_blank">Дополнительное соглашения к
            опционному соглашению
          </a> введите код.
            Он направлен на Вашу почту.
          </p>
        </div>

      </div>
    </div>
  </div>
  <div class="col-12 mb-5">
    <div class="card">
      <div class="card-header">
        <h5 class="mb-0">МОИ СЕРТИФИКАТЫ</h5>
      </div>
      <div class="row p-2">
        <div class="col-lg-4 col-md-6 " v-for="certificate of myCertificates">
          <div class="project-box">
            <span class="badge bg-success">Выпущен</span>
            <h6 class="mt-2">Сертификат Платформы "Рантье"</h6>
            <p>{{ certificate.certificateNumber }}</p>
            <div class="row details">
              <div class="col-6"><span>Количество </span></div>
              <div class="col-6 font-primary">{{ format(parseFloat(certificate.denominations).toFixed(3)) }}</div>
              <div class="col-6"><span>Цена сертификата на момент покупки</span></div>
              <div class="col-6 font-primary">{{ format(certificate.amount) }} &#8381;</div>
              <div class="col-6"><span>Текущая стоимость</span></div>
              <div class="col-6 font-primary">
                {{ format(formatTwo(certificate.denominations * certificatePrice).toFixed(3)) }} &#8381;
              </div>
              <div class="col-6"><span>Дата покупки</span></div>
              <div class="col-6 font-primary">{{ new Date(certificate.created_at).toLocaleDateString('ru-Ru') }}</div>
              <div class="col-6"><span>Опционное соглашение</span></div>
              <div class="col-6 font-primary">
                <button class="btn btn-primary" @click="getDogovor(certificate.id)">Скачать</button>
              </div>
              <div class="col-6" v-if="email==='test@test.test'"><span>Чек об операции</span></div>
              <div class="col-6 font-primary" v-if="email==='test@test.test'"><a href="https://rentier.world/test/1.pdf"
                                                                                 target="_blank">Посмотреть</a>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CertificateDataService from "@/services/CertificateDataService";
import AlertSuccess from "@/components/alert-succes.vue";
import alertError from "@/components/alert-error.vue";
import UserDataService from "@/services/UserDataService";
import MoneyDataService from "@/services/MoneyDataService";
import OperationsDataService from "@/services/OperationsDataService";

import codeConfirmation from "@/components/codeConfirmation.vue"

export default {
  name: 'oneProject',
  data() {
    return {
      countCertificateAvailable: Number,
      certificatePrice: Number,
      idSystemPayment: 0,
      isAmountAccount: false,
      isButtonDisabled: false,
      progressBarWidth: '100%',
      email: '',
      certificateCount: 1,
      isSummaValid: false,
      countPayment: Number,
      summaPayment: 1000,
      myCertificates: [],

      errorMessageOne: '',
      errorMessage: '',
      successMessageOne: '',
      successMessage: '',

      paymentData: {
        name: 'Покупка сертификата',
        summa: 1000,
        typePayment: 3,
        isAmountAccount: false
      },

      isCode: false,
      validate: {
        id: Number,
        code: '',
      },

      isCodeTransfer: false,
      isSendTransfer: false,
      transfer: {
        count: Number,
        email: ''
      },
      validateTransfer: {
        id: Number,
        code: '',
      },

    }
  },
  components: {
    AlertSuccess,
    alertError,
    codeConfirmation
  },
  mounted() {
    this.getMy();
  },
  methods: {
    updateCertificateCount() {
      this.certificateCount = Math.floor(this.summaPayment / this.certificatePrice * 10000) / 10000;
      this.paymentData.summa = Math.ceil(this.certificateCount * this.certificatePrice);
      console.log(this.paymentData.summa);
    },
    updateSummaPayment() {
      console.log(this.summaPayment);
      this.summaPayment = Math.ceil(this.certificateCount * this.certificatePrice);
      this.paymentData.summa = this.summaPayment;
    },
    formatTwo: function (number) {
      return Math.floor(number * 100) / 100;
    },
    format: val => `${val}`.replace(/(\d)(?=(\d{3})+([^\d]|$))/g, '$1 '),
    // short: num => 
    clickTransfer() {
      this.transfer.count = Number(this.transfer.count);
      if (this.transfer.count <= 0) {
        this.errorMessageOne = '';
        this.errorMessage = 'Укажите количество.'
        alertError(`Ввелите количество большо 0`)
        setTimeout(() => {
          this.errorMessage = '';
        }, 3500);
        return;
      }

      if (!this.transfer.email) {
        this.errorMessageOne = '';
        this.errorMessage = 'Укажите Email получателя.'
        setTimeout(() => {
          this.errorMessage = '';
        }, 3500);
        return;

      }

      this.isCodeTransfer = true;

      OperationsDataService
          .get({typeOperation: 6})
          .then(response => {
            this.validateTransfer.id = response.data;
          })

      UserDataService.getProfile(this.transfer.email)
          .then(response => {
            const profile = response.data;
            localStorage.setItem('userRecipient', JSON.stringify(profile));
          })

      UserDataService.get()
          .then(response => {
            const profile = response.data;
            localStorage.setItem('userSender', JSON.stringify(profile));
          })

    },
    codeValidateTransfer() {
      this.isSendTransfer = true;

      OperationsDataService
          .codeValidate(this.validateTransfer)
          .then(response => {
            const isValidate = response.data;
            if (isValidate) this.transferCertificate();
            else {
              this.errorMessage = 'Не верный код. Попробуйте ввести заново, или обновите страницу и сгенерируйте новый.'
              this.isSendTransfer = false;
            }
          })
    },
    transferCertificate() {
      CertificateDataService.transfer(this.transfer)
          .then(() => {
            this.successMessage = 'Перевод выполнен.'
            location.reload();
          })
          .catch(e => {
            this.errorMessage = e.response.data.message;
            console.log(e);
          });
    },

    clickPayment() {
      this.isButtonDisabled = true;

      if (this.summaPayment <= 0 || !this.summaPayment) {
        this.errorMessageOne = 'Укажите количество больше нуля.';
        this.errorMessage = '';

        setTimeout(() => {
          this.errorMessageOne = '';
        }, 3500);

        return;
      }

      this.isCode = true;
      OperationsDataService
          .get({typeOperation: 3})
          .then(response => {
            this.validate.id = response.data;
          })
    },
    codeValidate() {
      OperationsDataService
          .codeValidate(this.validate)
          .then(response => {
            const isValidate = response.data;
            if (isValidate) this.choiceAcquiring();
            else this.errorMessage = 'Не верный код. Попробуйте ввести заново, или обновите страницу и сгенерируйте новый.'
          })
    },
    choiceAcquiring() {
      localStorage.setItem('paymentData', JSON.stringify(this.paymentData));
      if (this.isAmountAccount) this.payment();
      if (this.idSystemPayment === 0 && !this.isAmountAccount) window.location.href = '/my/payments/cloudpayments';
      if (this.idSystemPayment === 1 && !this.isAmountAccount) this.payment();
    },

    getMy() {
      MoneyDataService.getIdPaymentService()
          .then(response => {
            this.idSystemPayment = Number(response.data.idSystemPayment);
            this.idSystemPayment = 1;
          })
          .catch(e => {
            console.log(e);
          });

      CertificateDataService.getMyCertificate()
          .then(response => {
            const certificate = response.data;
            this.myCertificates = certificate.myCertificates;

            this.countCertificateAvailable = certificate.countCertificateAvailable.value;
            this.certificatePrice = certificate.certificatePrice.value;
          })
          .catch(e => {
            console.log(e);
          });

      UserDataService.get()
          .then(response => {
            const user = response.data;
            this.email = user.user.email;
          }).catch(e => {
        console.log(e);
      });
    },

    checkSumma() {
      if (!this.summaPayment || this.summaPayment > 50000) {
        this.errorMessage = 'Укажите сумму сертификата от 500 до 50 000 рублей.'
        return false;
      }
      return true;
    },
    validateSumma() {
      this.isSummaValid = this.summaPayment >= 1000 && this.summaPayment <= this.countCertificateAvailable * this.certificatePrice;
    },
    payment() {
      if (!this.checkSumma()) return;
      MoneyDataService
          .payment({
            typePayment: 3,
            selectPaymentSystem: 1,
            isAmountAccount: this.isAmountAccount,
            summa: Number(this.summaPayment),
          })
          .then(response => {
            if (this.isAmountAccount)
              this.successMessage = 'Благодарим за покупку.'
            else {
              window.location.href = response.data.url;
              this.successMessage = 'Перейдите на сайт банка для оплаты ранга.'
            }
          })
          .catch(e => {
            this.errorMessage = 'Ошибка оплаты. ' + e.response.data.message;
          });
    },
    getDogovor(idCertificate) {
      CertificateDataService.paymentCertificateDogovor({idCertificate: idCertificate})
          .then(response => {
            const blob = new Blob([response.data], {type: 'application/pdf'});
            const downloadUrl = window.URL.createObjectURL(blob);
            // Создаем тег <a> для скачивания
            const link = document.createElement('a');
            link.href = downloadUrl;
            link.setAttribute('download', `rentier-esr-${idCertificate}.pdf`); // задаем имя файла для скачивания
            document.body.appendChild(link);
            link.click();

            // Очищаем ссылку на URL после скачивания
            link.remove();
            window.URL.revokeObjectURL(downloadUrl);
          })
          .catch(e => {
            console.log(e);
          });
    },
    checkBox() {
      if (this.isAmountAccount)
        localStorage.setItem('isAmountAccount', true);
      else
        localStorage.removeItem('isAmountAccount')
    },
    changeCount() {
      this.paymentData.summa = this.summaPayment;
    },
  }
}

</script>

<style scoped>
.top-right-alert {
  position: fixed;
  top: 60px;
  right: 20px;
  z-index: 9999;
}

</style>
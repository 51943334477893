<template>
  <div>
    <Breadcrumbs title="КУРСЫ"/>
    <div class="container-fluid">
      <div class="row">
        <div class="col-sm-12">
          <div class="card">
            <div class="card-header">
              <h5 class="card-header-upper">ДОСТУПНЫЕ КУРСЫ</h5>
            </div>
            <div class="card-body">
              <p>
                Каждый Пользователь после оплаты выбранного Курса получает
                доступ к приобретённым обучающим материалам.
              </p>
              <p>
                Если Пользователь имеет намерение получать вознаграждения за
                продвижение продуктов Компании, ему необходимо подписать
                (акцептовать) Партнёрский Договор (непубличную оферту).
              </p>
              <p>
                Компания производит начисление бонусов Партнёру на основании
                автоматически сформированного Акта оказанных услуг/выполненных
                работ.
              </p>
            </div>
            <alert-error v-if="errorMessage" :text="errorMessage"/>
            <alert-success v-if="successMessage" :text="successMessage"/>

            <div
                class="card-header mt-0 pt-0"
                v-if="this.isActivate && idSystemPayment < 3 && nextRank.name"
            >
              <div class="row">
                <div class="col-auto m-auto w-75">
                  <hr/>
                  <div class="form-body">
                    <div class="form-check">
                      <input
                          type="checkbox"
                          class="form-check-input"
                          id="isAmountAccount"
                          v-model="isAmountAccount"
                          @change="checkBox(1)"
                          :disabled="isCode"
                      />
                      <label class="form-check-label" for="isAmountAccount"
                      >Зачесть в погашение 100% стоимости средства со
                        внутреннего счета.
                        <a
                            href="../documents/internal-credit"
                            target="_blank"
                            v-if="isAmountAccount"
                        >Заявление на зачет.</a
                        ></label
                      >
                    </div>
                    <div class="mt-2 form-check">
                      <input
                          type="checkbox"
                          class="form-check-input"
                          id="promo"
                          @change="checkBox(2)"
                          :disabled="isCode"
                          v-model="isPromo"
                      />
                      <label class="form-check-label" for="promo"
                      >У меня есть промокод</label
                      >
                    </div>
                    <div class="" style="min-width: 200px">
                      <input
                          type="text"
                          class="form-control"
                          @input="checkPromo"
                          v-if="isPromo"
                      />
                    </div>
                    <p
                        v-if="isPromo && isPromoCodeActivate === null"
                        class="mt-2 font-success"
                    >
                      Введите промокод. Проверьте чтобы в нем не было пробелов.
                    </p>
                    <p
                        class="alert alert-danger mt-2"
                        v-if="isPromo && isPromoCodeActivate === false"
                    >
                      Ваш промокод не действителен. Проверьте срок действия, и
                      что он действительно подходит для покупки данного курса.
                    </p>
                    <p
                        class="alert alert-success mt-2"
                        v-if="isPromo && isPromoCodeActivate"
                    >
                      Ваш промокод действителен. И предоставляет скидку 50% при
                      оплате текущего курса. Промокод активируется сразу при
                      нажатии на кнопку 'Купить курс'.
                    </p>
                  </div>
                  <div class="m-2">
                    <button
                        class="btn btn btn-primary"
                        type="button"
                        v-if="!isCode"
                        @click="clickPayment"
                    >
                      Купить курс - {{ nextRank.name }} за
                      {{ nextRank.price }} рублей.
                    </button>
                  </div>
                  <div class="form-inline row mt-2" v-if="isCode">
                    <div class="row">
                      <div class="col-3">
                        <input
                            type="text"
                            class="form-control"
                            id="isCode"
                            placeholder="Код"
                            v-model="validate.code"
                        />
                      </div>
                      <button
                          class="btn btn btn-primary col"
                          type="button"
                          @click="codeValidate"
                      >
                        Подтвердить операцию
                      </button>
                    </div>
                    <p>
                      Для подтверждения операции введите код. Он направлен на
                      Вашу почту.
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <table class="table table-hover">
              <thead>
              <tr>
                <th scope="col">№<br/>КУРСА</th>
                <th scope="col">КУРС</th>
                <th scope="col">ЦЕНА КУРСА</th>
                <th scope="col">ПОКУПКА</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="rank of ranks" :key="rank.id">
                <th scope="row">{{ rank.id - 1 }}</th>

                <td>{{ rank.name }}</td>
                <td>
                  <div class="center">
                    {{ format(rank.price) + " ₽" }}
                  </div>
                </td>
                <td class="d-flex justify-content-center align-items-center">
                    <span
                        class="d-flex justify-content-center align-items-center"
                        v-if="rank.id < nextRank.id - 1 && isActivate"
                        v-html="status.ok"
                    ></span>
                  <span
                      class="d-flex justify-content-center align-items-center"
                      v-if="rank.id === nextRank.id - 1 && isActivate"
                      v-html="status.current"
                  ></span>
                  <span
                      class="d-flex justify-content-center align-items-center"
                      v-if="rank.id === nextRank.id && isActivate"
                      v-html="status.next"
                  ></span>
                  <span
                      class="d-flex justify-content-center align-items-center"
                      v-if="rank.id > nextRank.id || !isActivate"
                      v-html="status.no"
                  ></span>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12">
          <div class="card">
            <div class="card-header">
              <h5>ПРОМОКОДЫ</h5>
              <p>
                <b
                >Сформируйте промокод для участника своей сети! Промокод может
                  быть использован только 1 раз
                </b>
                и <b> только одним пользователем. </b> <br/>
                Срок действия промокода 90 дней со дня получения.
              </p>
              <button
                  v-if="this.user.isGeneratePromoCode"
                  @click.prevent="generate"
                  class="btn btn-primary"
                  type="button"
                  data-original-title="btn btn-link btn-lg"
              >
                Сгенерировать
              </button>
            </div>
            <div class="card-body">
              <select v-model="filterRank" class="form-select" @change="filter">
                <option disabled value="">Выберите статус</option>
                <option v-for="rank of ranks" :key="rank.id" :value="rank.id">
                  {{ rank.name }}
                </option>
              </select>

              <table class="table tables table-hover">
                <thead>
                <tr>
                  <th scope="col">Промо код</th>
                  <th scope="col"></th>
                  <th scope="col">Размер скидки, в %</th>
                  <th scope="col">Для курса</th>
                  <th scope="col">Активность</th>
                  <th scope="col">Дата создания</th>
                  <th scope="col">Дата окончания</th>
                </tr>
                </thead>
                <tbody>
                  <tr v-for="promoCode of promoCodesFilter" :key="promoCode.id">
                    <th scope="row">
                      <div>

                      </div>
                      {{ promoCode.code }}
                    </th>
                    <td class="">
                      <vue-feather
                          @click="clickCopyM(promoCode.code, index)"
                          class="ms-2 copy"
                          type="copy"
                      ></vue-feather>
                    </td>
                    <td>{{ promoCode.discountPercent }}%</td>
                    <td>{{ promoCode.rank.name }}</td>
                    <td>
                        <span class="font-success" v-if="!promoCode.isUsed"
                        >Не использован</span
                        >
                      <span class="font-danger" v-else>Использован</span>
                    </td>
                    <td>{{ formattedDate(promoCode.createdAt) }}</td>
                    <td>{{ formattedDate(promoCode.createdAt) }}</td>
                  </tr>
                </tbody>
              </table>
              <div class="default-according mt-5" id="accordion">
                <div
                    class="card"
                    v-for="(promoCode, index) of promoCodesFilter"
                    :key="promoCode.id"
                >
                  <div class="card-header" :id="'heading' + index">
                    <h5 class="mb-0">
                      <button
                          class="btn btn-link wrapper"
                          data-bs-toggle="collapse"
                          :data-bs-target="'#collapse' + index"
                          aria-expanded="true"
                          :aria-controls="'collapseOne' + index"
                      >
                        <span class="accountType d-flex">
                          {{ promoCode.code }}
                          <vue-feather
                              @click="clickCopyM(promoCode.code, index)"
                              class="ms-2 copy"
                              type="copy"
                          ></vue-feather>
                          <div
                              class="copied ms-2"
                              v-if="showCopiedMessage[index]"
                          >
                            <p>Скопированно</p>
                          </div>
                        </span>
                        <vue-feather
                            class="arrow-down"
                            type="chevron-down"
                        ></vue-feather>
                      </button>
                    </h5>
                  </div>
                  <div
                      class="collapse"
                      :id="'collapse' + index"
                      :aria-labelledby="'heading' + index"
                      data-bs-parent="#accordion"
                  >
                    <div class="card-body card-body-text">
                      <table class="table table-hover">
                        <tbody>
                        <tr>
                          <td><b> Размер скидки, в % : </b></td>
                          <td width="50%">
                            {{ promoCode.discountPercent }}%
                          </td>
                          <td></td>
                        </tr>
                        <tr>
                          <td><b> Для курса : </b></td>
                          <td width="50%">
                            {{ promoCode.rank.name }}
                          </td>
                          <td></td>
                        </tr>
                        <tr>
                          <td><b> Активность : </b></td>
                          <td width="50%">
                              <span
                                  class="font-success"
                                  v-if="!promoCode.isUsed"
                              >Не использован</span
                              >
                            <span class="font-danger" v-else
                            >Использован</span
                            >
                          </td>
                          <td></td>
                        </tr>
                        <tr>
                          <td><b> Дата создания : </b></td>
                          <td width="50%">
                            {{ formattedDate(promoCode.createdAt) }}
                          </td>
                          <td></td>
                        </tr>
                        <tr>
                          <td><b> Дата окончания : </b></td>
                          <td width="50%">
                            {{ formattedDate(promoCode.createdAt) }}
                          </td>
                          <td></td>
                        </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import RanksDataService from "@/services/RanksDataService";
import alertError from "@/components/alert-error.vue";
import AlertSuccess from "@/components/alert-succes.vue";
import SubscriptionDataService from "@/services/SubscriptionDataService";
import UserDataService from "@/services/UserDataService";
import PromoCodeDataService from "@/services/PromoCodeDataService";
import MoneyDataService from "@/services/MoneyDataService";
import OperationsDataService from "@/services/OperationsDataService";
import moment from "moment";

export default {
  data() {
    return {
      ranks: [],
      isActivate: false,
      nextRank: {id: 1},
      errorMessage: "",
      successMessage: "",
      user: {isGeneratePromoCode: false},
      promoCodes: [],
      promoCodesFilter: [],
      filterRank: "",
      isPromo: false,
      isAmountAccount: false,
      idSystemPayment: 0,
      isPromoCodeActivate: null,
      isCode: false,
      showCopiedMessage: [],
      validate: {
        id: Number,
        code: "",
      },
      paymentData: {
        name: "Оплата курса - ",
        summa: 0,
        typePayment: 2,
        isPromoCode: false,
        promoCode: "",
        isAmountAccount: false,
      },
      status: {
        ok: '<i class="font-success d-flex justify-content-center align-items-center" data-feather="alert-triangle"><i data-name="check-circle" data-tags="" data-type="check-circle" class="vue-feather vue-feather--check-circle"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-check-circle vue-feather__content"><path d="M22 11.08V12a10 10 0 1 1-5.93-9.14"></path><polyline points="22 4 12 14.01 9 11.01"></polyline></svg></i></i><span class="font-success"></span>',
        current:
            '<i class="font-roboto d-flex justify-content-center align-items-center" data-feather="alert-triangle"><i data-name="alert-circle" data-tags="warning,alert,danger" data-type="alert-circle" class="vue-feather vue-feather--alert-circle"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-alert-circle vue-feather__content"><circle cx="12" cy="12" r="10"></circle><line x1="12" y1="8" x2="12" y2="12"></line><line x1="12" y1="16" x2="12.01" y2="16"></line></svg></i></i><span class="font-roboto">Текущая</span>',
        next: '<i class="font-primary" data-feather="alert-triangle"><i data-name="check-circle" data-tags="" data-type="check-circle" class="vue-feather vue-feather--check-circle"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-check-circle vue-feather__content"><path d="M22 11.08V12a10 10 0 1 1-5.93-9.14"></path><polyline points="22 4 12 14.01 9 11.01"></polyline></svg></i></i><span class="font-primary">Доступна</span>',
        no: '<i class="font-danger" data-feather="alert-triangle"><i data-name="alert-triangle" data-tags="warning,alert,danger" data-type="alert-triangle" class="vue-feather vue-feather--alert-triangle"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-alert-triangle vue-feather__content"><path d="M10.29 3.86L1.82 18a2 2 0 0 0 1.71 3h16.94a2 2 0 0 0 1.71-3L13.71 3.86a2 2 0 0 0-3.42 0z"></path><line x1="12" y1="9" x2="12" y2="13"></line><line x1="12" y1="17" x2="12.01" y2="17"></line></svg></i></i><span class="font-danger">Не доступна</span>',
      },
    };
  },
  components: {
    AlertSuccess,
    alertError,
  },
  mounted() {
    this.getData();
  },
  methods: {
    format: (val) => `${val}`.replace(/(\d)(?=(\d{3})+([^\d]|$))/g, "$1 "),
    clickPayment() {
      this.isCode = true;

      OperationsDataService.get({typeOperation: 2}).then((response) => {
        this.validate.id = response.data;
      });
    },
    clickCopyM(text, index) {
      const input = document.createElement("input");
      input.setAttribute("value", text);
      document.body.appendChild(input);
      input.select();
      document.execCommand("copy");
      document.body.removeChild(input);

      this.showCopiedMessage[index] = true;
      setTimeout(() => {
        this.showCopiedMessage[index] = false;
      }, 3000);
    },
    formattedDate(date) {
      moment.locale("ru");
      return moment(date).format("D MMMM HH:mm");
      // if (date === this.event.meetingDateStart) {
      //   return moment(date).format("D MMMM HH:mm");
      // } else {
      //   return moment(date).format("D MMMM HH:mm");
      // }
      // if (date === this.event.meetingDateEnd) {
      //   return moment(date).format("D MMMM HH:mm");
      // } else {
      //   return moment(date).format("D MMMM HH:mm");
      // }
    },
    codeValidate() {
      OperationsDataService.codeValidate(this.validate).then((response) => {
        const isValidate = response.data;
        if (isValidate) this.choiceAcquiring();
        else
          this.errorMessage =
              "Не верный код. Попробуйте ввести заново, или обновите страницу и сгенерируйте новый.";
      });
    },
    choiceAcquiring() {
      if (this.isAmountAccount) this.payment();
      if (this.idSystemPayment === 0 && !this.isAmountAccount)
        window.location.href = "/my/payments/cloudpayments";
      if (this.idSystemPayment === 1 && !this.isAmountAccount) this.payment();
    },

    payment() {
      MoneyDataService.payment({
        typePayment: 2,
        selectPaymentSystem: 1,
        summa: 0,
        promoCode: this.paymentData.promoCode,
        isAmountAccount: this.isAmountAccount,
      })
          .then((response) => {
            if (this.isAmountAccount)
              this.successMessage = "Благодарим за покупку.";
            else {
              window.location.href = response.data.url;
              this.successMessage = "Перейдите на сайт банка для оплаты ранга.";
            }
          })
          .catch((e) => {
            this.errorMessage = "Ошибка оплаты. " + e.response.data.message;
            console.log(e);
          });
    },
    getData() {
      MoneyDataService.getIdPaymentService()
          .then((response) => {
            this.idSystemPayment = Number(response.data.idSystemPayment);
          })
          .catch((e) => {
            console.log(e);
          });

      RanksDataService.get()
          .then((response) => {
            this.ranks = response.data;
          })
          .catch((e) => {
            this.errorMessage = "Ошибка получения данных";
            console.log(e);
          });

      SubscriptionDataService.activate()
          .then((response) => {
            this.isActivate = response.data;

            if (!this.isActivate)
              this.errorMessage =
                  "Чтобы получить доступ к курсам, оплатите в начале подписку.";
          })
          .then(() => {
            if (this.isActivate)
              RanksDataService.getNextRank()
                  .then((response) => {
                    if (!response.data && response.data !== null)
                      this.nextRank.id = this.ranks.length + 1;
                    else this.nextRank = response.data;

                    this.paymentData.summa = this.nextRank.price;
                    this.paymentData.name += this.nextRank.name;
                    localStorage.setItem(
                        "paymentData",
                        JSON.stringify(this.paymentData)
                    );
                  })
                  .catch((e) => {
                    this.errorMessage = "Ошибка получения данных";
                    console.log(e);
                  });
          })
          .catch((e) => {
            console.log(e);
          });

      UserDataService.get()
          .then((response) => {
            this.user = response.data.user;
          })
          .catch((e) => {
            console.log(e);
          });

      PromoCodeDataService.get()
          .then((response) => {
            this.promoCodes = response.data;
            console.log("PROMO GET", this.promoCodes[1]);
            this.promoCodesFilter = this.promoCodes;
          })
          .catch((e) => {
            console.log(e);
          });
    },
    filter(event) {
      const id = event.target.value;
      let promoCodes = [];

      for (const promoCode of this.promoCodes)
        if (promoCode.rank.id === Number(id)) promoCodes.push(promoCode);

      this.promoCodesFilter = promoCodes;
    },
    generate() {
      PromoCodeDataService.generate()
          .then((response) => {
            this.promoCodes = response.data;
            this.promoCodesFilter = this.promoCodes;
            this.successMessage = "Промокоды успешно сгенерированы.";
          })
          .catch((e) => {
            console.log(e);
            this.errorMessage = "Ошибка генерации промокодов";
          });
    },
    checkPromo(event) {
      const promoCode = (this.name = event.target.value.toUpperCase());
      if (promoCode.length === 36)
        PromoCodeDataService.isPromoCode(promoCode)
            .then((response) => {
              this.isPromoCodeActivate = response.data;
              if (this.isPromoCodeActivate) {
                this.paymentData.isPromoCode = true;
                this.paymentData.promoCode = promoCode;
              }
              localStorage.setItem(
                  "paymentData",
                  JSON.stringify(this.paymentData)
              );
            })
            .catch(() => {
              this.errorMessage = "Ошибка проверки промокода";
            });
    },
    checkBox(type) {
      if (type === 1) {
        this.isPromo = false;
      }
      if (type === 2) {
        this.isAmountAccount = false;
      }
    },
  },
};
</script>

<style scoped>
.card-header-upper {
  text-decoration: uppercase;
}

.center {
  display: flex;
  align-items: center;
  justify-content: right;
}

.card-header-upper {
  text-decoration: uppercase;
}

.wrapper {
  position: relative;
  display: flex;
  justify-content: space-between;
}

.tables {
  display: block;
}

.default-according {
  display: none;
}

.copied {
  display: flex;
  align-items: center;
  justify-content: center;
}

.copied p {
  background-color: rgba(84, 186, 74, 0.8);
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px 15px;
  font-size: 14px;
  border-radius: 5px;
}

@media (max-width: 992px) {
  .default-according {
    display: block;
  }

  .tables {
    display: none;
  }
}
</style>

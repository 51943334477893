<template>
  <div class="img m-0 widget-hover">
    <div>
      <h1>Тикет-система</h1>
      <p>До направления обращения исполнительному директору, пожалуйста, воспользуйтесь чатом технической поддержки.
        При обращении, указывайте ФИО, дату и время обращения в техподдержку. <br> Будут рассматриваться только те обращения, которые предварительно направлялись в службу техподдержки и по ним не был решен вопрос.</p>
      <button class="SendMessage" @click="showMessageWindow = !showMessageWindow">
        Написать обращение
      </button>
    </div>

    <div v-if="showMessageWindow" class="overlay"></div>
    <div class="message-error-success">
      <alert-error v-if="showErrorWindow" :text="errorMessage" />
    </div>
    <div v-if="showMessageWindow" class="message-window">

      <form @submit.prevent="sendEmail">
        <div class="message-header">

          <h2 class="ticket-text">Оставить обращение</h2>
          <i type="button" @click="showMessageWindow = false" class="close-button"><img src="@\assets\svg\Close_MD.svg"
              alt="Close_MD"></i>
        </div>
        <hr>

        <div class="message-body">
          <div class="form-group groupOne">
            <label for="exampleInputSubject">Тема обращения</label>
            <textarea class="form-control" v-model="subject" placeholder="Задайте тему"></textarea>
          </div>
          <div class="form-group groupTwo">
            <label for="exampleInputMessage">Сообщение</label>
            <textarea class="form-control form-control-message" v-model="message" placeholder="Напишите сообщение"></textarea>
          </div>

        </div>

<!--        <div class="message-footer">-->
<!--          <div class="dropbox" @click="triggerFileInput">-->
<!--            <input type="file" id="fileInput" style="display: none;" />-->
<!--            <img src="@\assets\svg\Dropbox.svg" alt="Dropbox" />-->
<!--            <h3>Перетаскивайте файлы сюда или нажмите, чтобы загрузить.</h3>-->
<!--            <span>Рекомендуемое разрешение 1920х768.-->
<!--              Формат - JPG, WEBP, или PNG.</span>-->
<!--          </div>-->
<!--        </div>-->
        <hr>
        <div class="txt-danger txt-nb">Внимание! Вы можете за сутки отправить не более 5 сообщений.</div>
        <button class="btn btn-primary" type="submit">Отправить</button>
      </form>
    </div>
  </div>
  <div v-if="showSuccessWindow" class="overlay">
    <div class="success-window">
      <i type="button" @click="showSuccessWindow = false" class="close-Success">
        <img class="close-Success" src="@\assets\svg\Close_MD.svg" alt="Close_MD"></i>
      <div class="success-header">
        <div class="success-window-header">
        </div>
        <img src="@\assets\svg\Vector.svg" alt="Vector">
        <p>Благодарим за обращение</p>
        <span>Ваше обращение принято и будет рассмотрено в ближайшее время</span>
      </div>
    </div>
  </div>
</template>

<script>
  import axios from 'axios';
  import AlertSuccess from "@/components/alert-succes.vue";
  import AlertError from "@/components/alert-error.vue";
  import UserDataService from "@/services/UserDataService"
  export default {
    components: {AlertError, AlertSuccess},

    data() {
      return {
        subject: '',
        message: '',
        successMessage: "",
        errorMessage: "",
        showMessageWindow: false,
        showErrorWindow: false,
        showSuccessWindow: false,
        sendingState: true,
      };
    },
    methods: {
      // triggerFileInput() {
      //   document.getElementById('fileInput').click();
      // },
      // handleFileUpload(event) {
      //   const uploadedFiles = event.target.files;
      // },
      async sendEmail() {
        try {
          const token = localStorage.getItem('authToken');

          const data = {
            title: this.subject,
            message: this.message
          }
          await UserDataService.sendTicket(data)
          this.message = '';

          this.showMessageWindow = false; 
          this.showSuccessWindow = true;
          this.successMessage = "Ваше обращение принято и будет рассмотрено в ближайшее время!"
          setTimeout(() => {
            this.showSuccessWindow = false
            this.successMessage = ""
          }, 5000)
        } catch (error) {
          console.error(error.response.status );
          if (error.response.status === 409){
            this.showErrorWindow = true
            this.errorMessage = "Ошибка! Вы отправили 5 или более писем сегодня!"
            setTimeout(() => {
              this.showErrorWindow = false
              this.errorMessage = ""
            }, 5000)
          } else{
            this.errorMessage = "Ошибка! Проверьте заполненность полей!"
            this.showErrorWindow = true
            setTimeout(() => {
              this.showErrorWindow = false
              this.errorMessage = ""
            }, 5000)
            this.showErrorWindow = true
          }
        }
      },
    },
  };
</script>

<style scoped>
.txt-nb{
  padding-left: 10px;
}
.message-error-success{
  position:fixed;
  z-index: 10;
  right: 0;
  top: 60px;
}
  .message-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 18px 18px 5px;
  }

  .message-body {
    padding: 10px 32px;
  }

  .message-footer {
    padding: 24px 32px;
  }

  .ticket-text {
    font-size: 20px;
    font-weight: 500;
    color: #2F2F3B;
  }

  .groupOne {
    margin-bottom: 12px;
  }

  .dropbox {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 32px 88px;
    border: 2px dashed #7366FF;
    border-radius: 16px;
  }


  .dropbox h3 {
    font-size: 14px;
    font-weight: 500;
    max-width: 293px;
    text-align: center;
  }

  .dropbox span {
    font-size: 12px;
    font-weight: 400;
    max-width: 312px;
    text-align: center;
    color: #52526CBF;
  }

  .btn-primary {
    cursor: pointer;
    float: right;
    margin: 0px 32px;
  }

  .dropbox img {
    width: 50px;
    height: 50px;
  }

  .message-window {
    position: fixed;
    top: 50%;
    left: 50%;
    max-width:500px;
    width: 100%;
    padding-bottom: 50px;
    transform: translate(-50%, -50%);
    background-color: white;
    border-radius: 15px;
    z-index: 12;
  }

  .success-window {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #fff;
    padding: 20px;
    border-radius: 15px;
    z-index: 12;
    height: 281px;
    width: 561px;
  }

  .success-header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 16px 93px;
  }
.error-header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 16px 75px;
}
  .success-window p {
    color: #444444;
    font-size: 22px;
    font-weight: 500;
  }

  .success-window span {
    color: #52526CBF;
    font-size: 16px;
    font-weight: 400;
    max-width: 375px;
    text-align: center;
  }

  .img {
    background-image: url('@/assets/images/banner/ticket_img.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    padding: 164px 64px;
    border-radius: 15px;
    margin: 0px 15px;
    margin-bottom: 20px;
  }

  .img p {
    max-width: 534px;
  }

  .SendMessage {
    background-color: rgba(108, 92, 247, 1);
    padding: 6px 24px;
    color: white;
    border-radius: 6px;
  }

  .overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 10;
  }
</style>
<template>
  <div class="col-xl-8">
    <div class="admin_form row">
      <div class="col-xl-12">
        <div class="card shadow-lg card-container">
          <div class="card-body">
            <div class="todo">
              <ul class="wrapper-title d-flex justify-content-between p-2">
                <li>ИМЯ</li>
                <li>
                  <select id="projectCategoryField" class="form-control form-select" v-model="selected" >
                    <option v-for="option in options" v-bind:value="option.id" >{{ option.name }}</option>
                  </select>
                </li>
                <li>СТАТУС</li>
              </ul>
              <div class="todo-list-wrapper">
                <div class="todo-list-container">
                  <div class="todo-list-cont">
                    <ul v-if="!verifications" id="todo-list">
                      <li class="text-center">
                        Заявок с таким статусом не существует!
                      </li>
                    </ul>
                    <ul v-if="verifications" id="todo-list">
                      <li
                        v-for="(item, index) in verifications"
                        :key="index"
                        class="todo-item row"
                      >
                        <router-link class="col-6" :to="'/verification/my/list/' + item.id">
                          <div class="text-decoration-none">
                            {{ item.profile.firstName }}
                            {{ item.profile.lastName }}
                            {{ item.profile.middleName }}
                          </div>
                        </router-link>
                        <router-link v-if="selected === 1" class="col-3" :to="'/verification/my/list/' + item.id">
                          <div class="text-decoration-none text-black">
                            {{ formatDate(item.updated_at) }}
                          </div>
                        </router-link>
                        <router-link v-else class="col-3" :to="'/verification/my/list/' + item.id">
                          <div class="text-decoration-none text-black">
                            {{ formatDate(item.created_at) }}
                          </div>
                        </router-link>
                        <router-link class="col-3 d-flex justify-content-end align-items-center" :to="'/verification/my/list/' + item.id">
                          <span class="title">{{ item.name }}</span>
                          <div>
                            <span class="name">{{ item.status.name }}</span>
                          </div>
                        </router-link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <nav aria-label="Page navigation">
            <ul
              v-if="pages > 1"
              class="pagination pagination-primary d-flex justify-content-center aling-items-center my-3"
            >
              <li class="page-item">
                <a
                  class="page-link"
                  href="javascript:void(0)"
                  aria-label="Previous"
                  @click="
                    getVerifications(this.status, activePage - 1, this.pageSize)
                  "
                >
                  <span aria-hidden="true">«</span>
                  <span class="sr-only">Previous</span>
                </a>
              </li>

              <li v-for="pageIndex in pages" :key="pageIndex" class="page-item">
                <a
                  class="page-link"
                  @click="
                    getVerifications(this.status, pageIndex - 1, this.pageSize)
                  "
                  >{{ pageIndex }}</a
                >
              </li>

              <li class="page-item">
                <a
                  class="page-link"
                  href="javascript:void(0)"
                  aria-label="Next"
                  @click="
                    getVerifications(this.status, activePage + 1, this.pageSize)
                  "
                >
                  <span aria-hidden="true">»</span>
                  <span class="sr-only"></span>
                </a>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    pageSize: {
      type: Number,
      requests: true,
    },
    statusAcrive: {
      type: Number,
      requests: true,
    },
    verifications: {
      type: Array,
    },
    pages: {
      type: Number,
    },
    getVerifications: {
      type: Function,
    },
    activePage: {
      type: Number,
    },
    openIndex: {
      type: Number,
    },
  },
  data() {
    return {
      options:[
        {
          id: 1,
          name: "ДАТА СОЗДАНИЯ",
        },
        {
          id: 2,
          name: "ДАТА ИЗМЕНЕНИЯ",
        }
      ],
      selected: null,
    };
  },
  methods: {
    formatDate(dateString) {
      const date = new Date(dateString);

      const day = String(date.getDate()).padStart(2, '0');
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const year = date.getFullYear();

      const hours = String(date.getHours()).padStart(2, '0');
      const minutes = String(date.getMinutes()).padStart(2, '0');

      return `${day}.${month}.${year} ${hours}:${minutes}`;
    },
    toggleOpen(index) {
      if (this.openIndex === index) {
        this.openIndex = null;
      } else {
        this.openIndex = index;
      }
    },
    mounted() {
      this.getVerifications(this.statusAcrive, 0, this.pageSize);
      console.log("gssfgs")
    },
  },
  created() {
    // Восстанавливаем значение selected из localStorage, если оно там есть
    const savedSelected = localStorage.getItem('selected');
    if (savedSelected !== null) {
      this.selected = parseInt(savedSelected, 10);
    } else if (this.options.length > 0) {
      // Если в localStorage нет сохраненного значения, устанавливаем первый элемент массива options
      this.selected = this.options[0].id;
    }
  },
  watch: {
    selected(newSelected) {
      // Сохраняем значение selected в localStorage при его изменении
      localStorage.setItem('selected', newSelected);
    }
  }
};
</script>
import http from "../http-common";

export default {
    getServicesFromAdmin(state, pageServiceIdbyAdmin) {
        return http.get('/ItServices/administrators', {
            params: {
                status: state,
                page: pageServiceIdbyAdmin,
                pageSize: 15
            }
        });
    },
    getCurrent(pageServiceIdbyUser) {
        return http.get(`/ItServices/users`, {
            params: {
                pageServiceIdbyUser,
                pageSize: 15
            },
        });
    },

    getCurrentServiceFromAdmin(serviceId) {
        return http.get(`/ItServices/${serviceId}/administrators`, {
        });
    },
    postSendService(data) {
        return http.post(`/ItServices`, data)
    },
    getServices() {
        return http.get('/ItServices/statuses', {
        });
    },


    putItService(id, data) {
        return http.put(`/ItServices/${id}/administrators`, data);
    },
}


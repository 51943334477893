<template>
  <Breadcrumbs title="МОИ ФИНАНСЫ" />
  <div class="container-fluid">
    <div class="row">
      <div class="col-sm-12">
        <div class="card">
          <alert-error v-if="errorMessage" :text="errorMessage" class="top-right-alert"/>
          <alert-success v-if="successMessage" :text="successMessage" class="top-right-alert"/>

          <div class="row p-3">
            <h6>ПЕРЕВОД СРЕДСТВ</h6>
            <p>
              Перевод средств другому Пользователю доступен только c основного счёта. Для успешного перевода Вы должны быть верифицированы в системе* с заполнением паспортных данных.
            </p>
            <div class="col-sm-6 mt-1 col-lg-3">
              <input
                type="text"
                class="form-control"
                placeholder="Получатель, email"
                :disabled="isCode"
                v-model="userRecipientEmail"
              />
            </div>
            <div class="col-sm-6 mt-1 col-lg-3">
              <input
                type="Number"
                min="1"
                max="50000"
                class="form-control"
                placeholder="Сумма"
                :disabled="isCode"
                v-model="transferAmount"
              />
            </div>
            <div class="col-sm-6 mt-1 col-lg-3">
              <button
                class="btn btn-primary"
                type="button"
                @click="clickPayment"
                :disabled="isCode"
                data-original-title="btn btn-link btn-lg"
              >
                Перевести
              </button>
            </div>
          </div>
          <hr />
          <div class="row p-3">
            <h6>ВЫВОД СРЕДСТВ</h6>
            <p>
              Вывод средств из основного счёта доступен при балансе от 30 000 ₽. 
            </p>
            <p>
              Для успешного вывода Вы должны быть верифицированы в системе* с заполнением паспортных данных.
            </p>
            <div class="col-sm-6 mt-1 col-lg-3">
              <input
                type="number"
                min="1"
                max="50000"
                class="form-control"
                placeholder="Сколько"
                :disabled="isCode"
                v-model.number="withdrawAmount"
              />
            </div>
            <div class="col-sm-6 mt-1 col-lg-3">
              <button
                class="btn btn-primary"
                type="button"
                @click="clickWithdraw"
                :disabled="isCode"
                data-original-title="btn btn-link btn-lg"
              >
                Вывести
              </button>
            </div>
          </div>

          <div class="form-inline row p-3 pb-0" v-if="isCode">
            <div class="row">
              <div class="col-lg-3 col-sm-6">
                <input
                  type="text"
                  class="form-control"
                  id="isCode"
                  placeholder="Код"
                  v-model="validate.code"
                />
              </div>
              <button
                class="btn btn btn-primary col-lg-3 col-sm-6"
                type="button"
                :disabled="this.isSend || this.validate.code.length < 3"
                @click="codeValidate"
              >
                Подтвердить операцию
              </button>
            </div>
            <p class="font-warning">
              Для подтверждения операции и подписания
              <a href="../documents/money-transfer-notification" target="_blank"
                >Уведомление об уступки прав</a
              >
              введите код. Он направлен на Вашу почту. Вы с получателем можете
              использовать этот договор для оформления своих правоотношений.
              <a href="../documents/money-transfer" target="_blank"
                >Образец Договора уступки прав.</a
              >
            </p>
          </div>
          <hr />
          <div class="col-sm-12">
            <div class="card">
              <div class="card-header">
                <h5>БАЛАНС СЧЕТОВ</h5>
                <span></span>
              </div>
              <div class="card-body">
                <div class="default-according" id="accordion">
                  <div
                    class="card"
                    v-for="(item, index) of money"
                    :key="item.id"
                  >
                    <div class="card-header" :id="'heading' + index">
                      <h5 class="mb-0" @click="toggleCollapse(index)">
                        <button
                          class="btn btn-link"
                          data-bs-toggle="collapse"
                          :data-bs-target="'#collapse' + index"
                          aria-expanded="true"
                          :aria-controls="'collapseOne' + index"
                          v-if="item.missedPoints"
                        >
                          <span  class="accountType">
                            {{ item.accountType.name }}
                            <span class="font-success">
                              -
                              {{ Number(item.points).toLocaleString() }} ₽</span
                            >
                            <span class="font-danger">
                              ({{
                                Number(item.missedPoints).toLocaleString()
                              }}
                              ₽)</span
                            >
                          </span>
                        </button>
                        <button
                          class="btn btn-link"
                          data-bs-toggle="collapse"
                          :data-bs-target="'#collapse' + index"
                          aria-expanded="true"
                          :aria-controls="'collapseOne' + index"
                          v-else
                        >
                          <span class="accountType"
                            >{{ item.accountType.name }}
                            <span class="green_finance"
                              >+ {{ item.points }}</span
                            >
                          </span>
                        </button>
                        <div class="sidebar-toggles">
                          <vue-feather
                            class="status_toggle middle sidebar-toggle"
                            type="chevron-down"
                            id="sidebar-toggle"
                            :class="{ rotate180: isCollapsed[index] }"
                          ></vue-feather>
                        </div>
                      </h5>
                    </div>
                    <div
                      class="collapse"
                      :id="'collapse' + index"
                      :aria-labelledby="'heading' + index"
                      data-bs-parent="#accordion"
                    >
                      <div class="card-body card-body-text">
                        Баланс: {{ Number(item.points).toLocaleString() }} ₽
                        <br />
                        Упущенная прибыль:
                        {{ Number(item.missedPoints).toLocaleString() }} ₽
                      </div>
                    </div>
                  </div>
                </div>
                <p class="mt-3">
                  <b>* Верификация личности  через предоставление подлинного  документа, удостоверяющего личность — паспорт,</b> необходима для соответствия законодательству Российской Федерации и международным требованиям, направленным на противодействие легализации доходов, полученных преступным путём и финансирования терроризма. Проект “Рантье” изначально закладывает практику минимизации рисков претензий со стороны регуляторов. Просим Вас с пониманием отнестись к необходимости верификации для безопасного совершения финансовых операций.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AlertError from "@/components/alert-error.vue";
import AlertSuccess from "@/components/alert-succes.vue";
import UserDataService from "@/services/UserDataService";
import MoneyDataService from "@/services/MoneyDataService";
import OperationsDataService from "@/services/OperationsDataService";

export default {
  data() {
    return {
      isCollapsed: {},
      referralCode: "",
      errorMessage: "",
      successMessage: "",
      money: [],
      userRecipientEmail: "",
      transferAmount: 0,
      withdrawAmount: 0,
      isCode: false,
      isSend: false,
      profile: {
        user: {},
        recipient: {},
      },
      validate: {
        id: Number,
        code: "",
      },
    };
  },
  components: {
    AlertSuccess,
    AlertError,
  },
  mounted() {
    MoneyDataService.get()
      .then((response) => {
        this.money = response.data;
        console.log(this.money);
      })
      .catch((e) => {
        console.log(e);
      });
  },
  methods: {
    toggleCollapse(index) {
      for (let key in this.isCollapsed) {
        if (key !== index) {
          this.isCollapsed[key] = false;
        }
      }
      this.isCollapsed[index] = !this.isCollapsed[index];
    },
    clickPayment() {
      if (this.transferAmount < 1) {
        this.errorMessage = "Укажите сумму больше 1 рубля.";
        setTimeout(() => {
      this.errorMessage = ''; 
    }, 3500);
        return;
      }

      if (!this.userRecipientEmail) {
        this.errorMessage = "Укажите Email получателя.";
        setTimeout(() => {
      this.errorMessage = ''; 
    }, 3500);
        return;
      }
      this.isCode = true;

      OperationsDataService.get({ typeOperation: 5 }).then((response) => {
        this.validate.id = response.data;
      });

      UserDataService.getProfile(this.userRecipientEmail).then((response) => {
        const profile = response.data;
        localStorage.setItem("userRecipient", JSON.stringify(profile));
      });

      UserDataService.get().then((response) => {
        const profile = response.data;
        localStorage.setItem("userSender", JSON.stringify(profile));
      });

      localStorage.setItem("amount", this.transferAmount);
    },

    clickWithdraw() {
      if (this.withdrawAmount < 30000) {
        this.errorMessage = "Укажите сумму больше 30 000 рублей.";
        setTimeout(() => {
      this.errorMessage = ''; 
    }, 3500);
      } else {
        this.moneyWithdraw();
      }
    },

    codeValidate() {
      this.isSend = true;

      OperationsDataService.codeValidate(this.validate).then((response) => {
        const isValidate = response.data;
        if (isValidate) this.moneyTransfer();
        else {
          this.errorMessage =
            "Не верный код. Попробуйте ввести заново, или обновите страницу и сгенерируйте новый.";
          this.isSend = false;
          setTimeout(() => {
      this.errorMessage = ''; 
    }, 3500);
        }
      });
    },
    moneyTransfer() {
      MoneyDataService.moneyTransfer({
        userRecipientEmail: this.userRecipientEmail,
        amount: Number(this.transferAmount),
      })
        .then(() => {
          this.successMessage = "Перевод выполнен.";
          setTimeout(() => {
      this.successMessage = ''; 
    }, 3500);
          location.reload();
        })
        .catch((e) => {
          this.errorMessage = e.response.data.message;
          console.log(e);
        });
    },

    moneyWithdraw() {
      MoneyDataService.withdraw({
        amount: this.withdrawAmount,
      })
        .then(() => {
          this.successMessage = "Заявка на вывод средств создана";
          setTimeout(() => {
      this.successMessage = ''; 
    }, 3500);
          location.reload();
        })
        .catch((e) => {
          this.errorMessage = e.response.data.message;
          console.log(e);
        });
    },
  },
  computed: {
    isWithdrawAvailable() {
      if (this.money[0]) {
        return Number(this.money[0].points) >= 30000;
      }
    },
  },
};
</script>

<style scoped>
.card-body-text {
  font-size: 15px !important;
}
h5 {
  position: relative;
}
.sidebar-toggles {
  position: absolute;
  right: 1%;
  top: 30%;
}
.rotate180 {
  transform: rotate(90deg);
  transition: all .3s;
}
.top-right-alert {
    position: fixed;
    top: 60px; 
    right: 20px;
    z-index: 9999;
}
</style>
